import React, { useState, Dispatch, SetStateAction, useEffect } from 'react'
import { View, StyleSheet, TextInput } from 'react-native'
import Input from '../../../components/Input'
import QuestionHeader from '../../Survey/components/QuestionHeader'
import PasswordInput from './PasswordInput'
import { KeyboardListenerInputProps } from '../../../hooks/useKeyboardListener'
import { t } from 'i18n-js'

type UserSignupFlowProps = {
  fullName: string
  setFullName: Dispatch<SetStateAction<string>>
  email: string
  setEmail: Dispatch<SetStateAction<string>>
  password: string
  setPassword: Dispatch<SetStateAction<string>>
  confirmPassword: string
  setConfirmPassword: Dispatch<SetStateAction<string>>
  oldPassword?: string
  setOldPassword?: Dispatch<SetStateAction<string>>
  screen: number
  errorMessage?: string
  update?: boolean
  keyboardListenerInputProps?: KeyboardListenerInputProps<TextInput>
}

export default function UserSignupFlow({
  fullName,
  setFullName,
  email,
  setEmail,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  oldPassword,
  setOldPassword,
  screen,
  errorMessage,
  update,
  keyboardListenerInputProps,
}: UserSignupFlowProps) {
  return (
    <>
      {screen === 1 && (
        <>
          <QuestionHeader question={t('sign-up.enter-name')} strong />
          <Input
            onChangeText={text => setFullName(text)}
            value={fullName}
            placeholder={t('sign-up.name')}
            keyboardListenerInputProps={keyboardListenerInputProps}
          />
        </>
      )}
      {screen === 2 && (
        <>
          <QuestionHeader question={t('sign-up.enter-email')} strong />
          <Input
            onChangeText={text => setEmail(text)}
            value={email}
            placeholder={t('sign-up.email')}
            autoCapitalize={'none'}
            keyboard="email-address"
            error={errorMessage}
            keyboardListenerInputProps={keyboardListenerInputProps}
          />
        </>
      )}

      {screen === 3 && (
        <>
          {update && oldPassword !== undefined && setOldPassword && (
            <>
              <QuestionHeader question={t('sign-up.old-password')} strong />
              <View style={{ gap: 6 }}>
                <Input
                  onChangeText={text => setOldPassword(text)}
                  value={oldPassword}
                  secure
                  placeholder={t('sign-up.old-password')}
                  autoCapitalize={'none'}
                />
              </View>
            </>
          )}
          <QuestionHeader
            question={
              update ? t('sign-up.new-password') : t('sign-up.enter-password')
            }
            strong
          />
          <PasswordInput
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
          />
        </>
      )}
    </>
  )
}

const styles = StyleSheet.create({})
