import { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import {
  NativeSyntheticEvent,
  Platform,
  TextInput,
  TextInputFocusEventData,
} from 'react-native'

function checkIsMobileBrowser(a: any) {
  if (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    ) {
      return true
    }
  }
  return false
}

const isMobileBrowser = checkIsMobileBrowser(
  navigator?.userAgent || (navigator as any)?.vendor || (window as any)?.opera
)

export type KeyboardListenerInputProps<T> = {
  ref: RefObject<T>
  onFocus: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void
  onBlur: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void
}

export function useKeyboardListener<T extends TextInput>(): {
  keyboardOpen: boolean
  inputProps: KeyboardListenerInputProps<T>
} {
  const inputRef = useRef<T>(null)

  // Ignore keyboard listener if not on web or not a mobile browser (i.e. must be on Web and a Mobile/Tablet)
  if (Platform.OS !== 'web' || !isMobileBrowser) {
    return {
      inputProps: {
        onBlur: () => {},
        onFocus: () => {},
        ref: inputRef,
      },
      keyboardOpen: false,
    }
  }

  const [keyboardOpen, setKeyboardOpen] = useState(false)

  const lastVisualViewportWidth = useRef(0)
  const lastVisualViewportHeight = useRef(0)

  const didBlur = useRef(false)

  const onBlur = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      // SetTimeout of 0ms to give event loop time to process for example button
      // presses through keyboard (ScrollView
      // "keyboardShouldPersistTaps='handled'"" for example). Without this, the
      // button press wouldn't go through because the state change would
      // re-render before the button press could get handled.
      setTimeout(() => {
        setKeyboardOpen(false)
      }, 0)
    },
    [setKeyboardOpen]
  )

  const onFocus = useCallback(
    (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      setKeyboardOpen(true)
    },
    [setKeyboardOpen]
  )

  // Catch keyboard closes that don't trigger onBlur events. "Done" on iOS and back button on Android.
  useEffect(() => {
    ;(window as any).visualViewport.addEventListener('resize', (event: any) => {
      const visualViewportHeight = event.target.height
      const visualViewportWidth = event.target.width

      // Relying on "onBlur" events are more solid, this is only a fallback.
      // "onBlur" get's called before a resize, so let's check if we did blur.
      if (didBlur.current === true) {
        didBlur.current = false
        return
      } else {
        // Might have closed keyboard with "Done" or back-button

        // Make sure width didn't change, as that wouldn't constitute a keyboard close
        if (lastVisualViewportWidth.current !== visualViewportWidth) return

        // We test this with an approximate keyboard height difference in resize
        const approxPhoneKeyboardHeight = 250
        const phoneKeyboardHeightThreshold = 40
        const heightDifference =
          visualViewportHeight - lastVisualViewportHeight.current

        // Only look for close-events (i.e. last height should be smaller than now, and thus a positive height difference)
        if (
          Math.abs(heightDifference - approxPhoneKeyboardHeight) <
          phoneKeyboardHeightThreshold
        ) {
          // @ts-ignore
          inputRef.current?.blur()
        }

        lastVisualViewportHeight.current = visualViewportHeight
        lastVisualViewportWidth.current = visualViewportWidth
      }
    })
    lastVisualViewportHeight.current = window.visualViewport?.height ?? 0
    lastVisualViewportWidth.current = window.visualViewport?.width ?? 0
  }, [])

  return {
    inputProps: {
      ref: inputRef,
      onFocus,
      onBlur,
    },
    keyboardOpen,
  }
}
