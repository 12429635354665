import React, { useContext, useEffect, useState } from 'react'
import {
  View,
  Text,
  Button,
  StyleSheet,
  ScrollView,
  RefreshControl,
} from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { ProfileNavigatorParamList } from '../../types/NavigationTypes'
import { RouteProp, useNavigation } from '@react-navigation/native'
import Input from '../../components/Input'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { endSession } from '../../redux/slices/sessionSlice'
import PrimaryButton from '../../components/PrimaryButton'
import Page from '../../components/Page'
import Ionicons from '@expo/vector-icons/Ionicons'
import { theme } from '../../theme/theme'
import {
  useGetPreviousSurveysQuery,
  useGetSurveysQuery,
} from '../../redux/services/coreApi'
import SurveyCard from '../Home/components/SurveyCard'

type PreviousAnswersScreenNavigationProps = NativeStackNavigationProp<
  ProfileNavigatorParamList,
  'PreviousAnswersScreen'
>
type PreviousAnswersScreenRouteProps = RouteProp<
  ProfileNavigatorParamList,
  'PreviousAnswersScreen'
>

type Props = {
  navigation: PreviousAnswersScreenNavigationProps
  route: PreviousAnswersScreenRouteProps
}

export default function PreviousAnswersScreen({ navigation, route }: Props) {
  const { data: surveys, isFetching, refetch } = useGetPreviousSurveysQuery()
  const session = useSelector((state: RootState) => state.session)

  const { colors } = theme

  return (
    <Page title="Previous answers">
      <View style={{ flex: 1 }}>
        <ScrollView
          style={{
            marginHorizontal: -theme.pagePadding,
            paddingHorizontal: theme.pagePadding,
          }}
          refreshControl={
            <RefreshControl refreshing={isFetching} onRefresh={refetch} />
          }
        >
          {surveys?.map((survey, i) => (
            <SurveyCard
              key={survey.id}
              previous
              survey={survey}
              onPress={() =>
                navigation.navigate('SurveyNavigator', {
                  screen: 'SurveyReadyScreen',
                  params: {
                    surveyId: survey.id,
                  },
                })
              }
            />
          ))}
        </ScrollView>
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    flex: 1,
  },
})
