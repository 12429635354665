import React from 'react'
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native'
import { SurveyDto } from '../../../src/types/coreApi-types'
import { theme } from '../../../theme/theme'
import {
  Description,
  Strong,
  StrongDescription,
  StrongHeader,
} from '../../../components/Text'
import moment from 'moment'
import { formatEventDateAndTime } from '../../../utils/formatEventDateAndTime'
import { t } from 'i18n-js'

type Props = {
  survey: SurveyDto
  onPress: () => void
  previous?: boolean
}

export default function SurveyCard({ survey, onPress, previous }: Props) {
  const { colors } = theme

  const isToday = moment().isSame(new Date(), 'day')

  const isSurveyUpcoming = moment().isBefore(survey?.event.startsAt)

  const happeningNow =
    moment(survey.event.startsAt).isSame(survey.event.expiresAt) && isToday
      ? true
      : moment().isBetween(survey.event.startsAt, survey.event.expiresAt)

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={[styles.container, { borderColor: colors['border-subtle'] }]}
      onPress={onPress}
    >
      <View
        style={{
          justifyContent: 'space-between',
        }}
      >
        <Image
          style={styles.image}
          source={{
            uri: survey.introImage,
          }}
        />
        <View style={{ gap: 4, padding: 12, justifyContent: 'center' }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {!isSurveyUpcoming && (
              <StrongDescription
                style={{
                  color: colors.secondary,
                  fontSize: theme.textSizes.sm,
                }}
              >
                {t('events.happening-now').toUpperCase() +
                  (survey.event.codeRequired
                    ? ' - ' +
                      (survey.totalEventCodes - survey.usedEventCodes) +
                      ` ${t('events.spots-left').toUpperCase()}`
                    : '')}
              </StrongDescription>
            )}

            {isSurveyUpcoming && (
              <Description
                style={{
                  color: colors.description,
                  fontSize: theme.textSizes.sm,
                }}
              >
                {formatEventDateAndTime(
                  survey.event.startsAt,
                  survey.event.expiresAt
                )}
              </Description>
            )}
            {/* {!previous && happeningNow && (
              <StrongDescription
                style={{
                  color: colors.secondary,
                  fontSize: theme.textSizes.sm,
                }}
              >
                {t('events.happening-now').toUpperCase() +
                  (survey.event.codeRequired
                    ? ' - ' +
                      (survey.totalEventCodes - survey.usedEventCodes) +
                      ` ${t('events.spots-left').toUpperCase()}`
                    : '')}
              </StrongDescription>
            )}
            {!previous && !happeningNow && (
              <Description
                style={{
                  color: colors.description,
                  fontSize: theme.textSizes.sm,
                }}
              >
                {formatEventDateAndTime(
                  survey.event.startsAt,
                  survey.event.expiresAt
                )}
                
              </Description>
            )} */}
            <Description
              style={{
                color: colors.description,
                fontSize: theme.textSizes.sm,
              }}
            >
              {survey.city}
            </Description>
          </View>
          <StrongHeader
            numberOfLines={1}
            style={{ fontSize: theme.textSizes.xl }}
          >
            {survey.title}
          </StrongHeader>
        </View>
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 12,
    marginVertical: 10,
    borderWidth: 1,
    zIndex: 1,
  },
  image: {
    width: '100%',
    height: 90,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
})
