import Constants from 'expo-constants'

export const SENTRY_DSN: string = Constants.expoConfig?.extra?.SENTRY_DSN || ''

export const API_URL: string = Constants.expoConfig?.extra?.API_URL || 'https://www.api.sensimate.dk/'

export const IS_DEV: boolean =
  Constants.expoConfig?.extra?.APP_VARIANT === 'development' || false

export const IS_STAGING: boolean =
  Constants.expoConfig?.extra?.APP_VARIANT === 'staging' || false
