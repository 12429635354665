import React, { Dispatch, SetStateAction } from 'react'
import { View, StyleSheet, ScrollView } from 'react-native'
import { QuestionDto } from '../../../src/types/coreApi-types'
import { theme } from '../../../theme/theme'
import MultipleChoiceQuestion from '../questions/MultipleChoiceQuestion'
import TextQuestion from '../questions/TextQuestion'
import SingleChoiceQuestion from '../questions/SingleChoiceQuestion'
import MoodQuestion from '../questions/MoodQuestion'
import MultipleChoiceImageQuestion from '../questions/MultipleChoiceImageQuestion'
import RankingQuestion from '../questions/RankingQuestion'
import BatteryChoiceQuestion from '../questions/BatteryChoiceQuestion'
import BatterySliderQuestion from '../questions/BatterySliderQuestion'
import NumberQuestion from '../questions/NumberQuestion'
import QuestionHeader from './QuestionHeader'
import { t } from 'i18n-js'

type Props = {
  question: QuestionDto
  answersState: string[]
  setAnswersState: Dispatch<SetStateAction<string[]>>
  columnAnswersState: string[]
  setColumnAnswersState: Dispatch<SetStateAction<string[]>>
}

export default function SurveyQuestion({
  question,
  answersState,
  setAnswersState,
  columnAnswersState,
  setColumnAnswersState,
}: Props) {
  const { colors } = theme

  const renderQuestion = () => {
    switch (question.questionType) {
      case 'MultipleChoice':
        return (
          <>
            <QuestionHeader
              question={question.question}
              description={t('events.select-one-or-more')}
              minimum={question.minimum}
              maximum={question.maximum}
            />
            <MultipleChoiceQuestion
              question={question}
              answersState={answersState}
              setAnswersState={setAnswersState}
            />
          </>
        )
      case 'Text':
        return (
          <>
            <QuestionHeader
              question={question.question}
              description={t('events.write-your-answer')}
              minimum={question.minimum}
              maximum={question.maximum}
            />
            <TextQuestion
              question={question}
              answersState={answersState}
              setAnswersState={setAnswersState}
            />
          </>
        )
      case 'Number':
        return (
          <NumberQuestion
            question={question}
            answersState={answersState}
            setAnswersState={setAnswersState}
          />
        )
      case 'SingleChoice':
        return (
          <>
            <QuestionHeader
              question={question.question}
              description={t('events.select-one-answer')}
            />
            <SingleChoiceQuestion
              question={question}
              answersState={answersState}
              setAnswersState={setAnswersState}
            />
          </>
        )
      case 'Mood':
        return (
          <MoodQuestion
            question={question}
            answersState={answersState}
            setAnswersState={setAnswersState}
          />
        )
      case 'MultipleChoiceImage':
        return (
          <>
            <QuestionHeader
              question={question.question}
              description={t('events.select-one-or-more-images')}
              minimum={question.minimum}
              maximum={question.maximum}
            />
            <MultipleChoiceImageQuestion
              question={question}
              answersState={answersState}
              setAnswersState={setAnswersState}
            />
          </>
        )
      case 'Ranking':
        return (
          <>
            <QuestionHeader
              question={question.question}
              description={t('events.rank-the-options')}
              minimum={question.minimum}
              maximum={question.maximum}
            />
            <RankingQuestion
              question={question}
              answersState={answersState}
              setAnswersState={setAnswersState}
            />
          </>
        )
      case 'BatteryChoice':
        return (
          <>
            <QuestionHeader
              question={question.question}
              description={t('events.rate-each-quality')}
              minimum={question.minimum}
              maximum={question.maximum}
            />
            <BatteryChoiceQuestion
              question={question}
              answersState={answersState}
              setAnswersState={setAnswersState}
              columnAnswersState={columnAnswersState}
              setColumnAnswersState={setColumnAnswersState}
            />
          </>
        )
      case 'BatterySlider':
        return (
          <>
            <QuestionHeader
              question={question.question}
              description={t('events.rate-each-quality')}
            />
            <BatterySliderQuestion
              question={question}
              answersState={answersState}
              setAnswersState={setAnswersState}
              columnAnswersState={columnAnswersState}
              setColumnAnswersState={setColumnAnswersState}
            />
          </>
        )
    }
  }

  return (
    <View style={{ flex: 1, marginBottom: 16 }}>
      <ScrollView
        style={{
          marginHorizontal: -theme.pagePadding,
          paddingHorizontal: theme.pagePadding,
        }}
      >
        {renderQuestion()}
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'black',
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: 5,
  },
})
