// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from '../../constants'
import { RootState } from '../store'
import { Mutex } from 'async-mutex'
import {
  SurveyDto,
  QuestionDto,
  AnswerDto,
  AnswerCreateRequest,
  ProfileCreateRequest,
  ProfileDto,
  UserDto,
  UserCreateRequest,
  UserAuthenticateDto,
  UserAuthenticateRequest,
  EventCodeDto,
  ValidateCodeRequest,
  FeedbackDto,
  FeedbackCreateRequest,
  EventRatingDto,
  EventRatingCreateRequest,
  ResetPasswordRequest,
  ChangePasswordRequest,
  ProfileUpdateRequest,
  ValidateEmailRequest,
  ValidateEmailDto,
  ValidateResetCodeRequest,
} from '../../src/types/coreApi-types'
import { endSession, startSession } from '../slices/sessionSlice'

const mutex = new Mutex()

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: async (headers, api) => {
    const state = api.getState() as RootState
    const temporaryGuestProfile = state.session.temporaryGuestProfile
    const onTemporaryRoute = state.session.onTemporaryRoute
    if (temporaryGuestProfile && onTemporaryRoute) {
      console.debug('Using temporary guest profile in query')
      headers.set('authorization', temporaryGuestProfile.id)
      return headers
    }
    const token = state.session.token
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
      return headers
    }
    const guestProfile = state.session.guestProfile
    if (guestProfile) {
      headers.set('authorization', guestProfile.id)
    }
    return headers
  },
})

type BaseQueryType = ReturnType<typeof fetchBaseQuery>

const baseQueryWithReauth: (baseQuery: BaseQueryType) => BaseQueryType =
  baseQuery => async (args, api, extraOptions) => {
    await mutex.waitForUnlock()
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire()
        try {
          const state = api.getState() as RootState
          const refreshToken = state.session.refreshToken
          if (!refreshToken) {
            api.dispatch(endSession())
          }
          const refreshResult = (await baseQuery(
            { url: '/users/refresh', method: 'POST', body: { refreshToken } },
            api,
            extraOptions
          )) as { data: { token: string; refreshToken: string } }
          if (refreshResult.data) {
            api.dispatch(
              startSession({
                token: refreshResult.data.token,
                refreshToken: refreshResult.data.refreshToken,
              })
            )
            result = await baseQuery(args, api, extraOptions)
          } else {
            api.dispatch(endSession())
          }
        } finally {
          release()
        }
      } else {
        await mutex.waitForUnlock()
        result = await baseQuery(args, api, extraOptions)
      }
    }
    return result
  }

// Define a service using a base URL and expected endpoints
export const coreApi = createApi({
  reducerPath: 'coreApi',
  baseQuery: baseQueryWithReauth(baseQuery),
  tagTypes: ['Surveys', 'PreviousSurveys', 'Answers', 'EventRating', 'Profile'],
  endpoints: builder => ({
    getSurveys: builder.query<SurveyDto[], string | void>({
      query: lang => (lang ? `/surveys?lang=${lang}` : '/surveys'),
      providesTags: ['Surveys'],
    }),
    getPreviousSurveys: builder.query<SurveyDto[], void>({
      query: () => '/surveys/previous',
      providesTags: ['PreviousSurveys'],
    }),
    getSurvey: builder.query<SurveyDto, { surveyId: number, locale: string }>({
      query: ({ surveyId, locale }) => `/surveys/${surveyId}`,
      providesTags: ['Surveys'],
    }),
    getQuestions: builder.query<QuestionDto[], number>({
      query: surveyId => `/surveys/${surveyId}/questions`,
    }),
    getAnswerById: builder.query<AnswerDto, number>({
      query: questionId => `/answers/${questionId}`,
      providesTags: (result, error, id) => [{ type: 'Answers', id }],
    }),
    createAnswer: builder.mutation<AnswerDto, AnswerCreateRequest>({
      query: answer => ({
        url: '/answers',
        method: 'POST',
        body: answer,
      }),
      invalidatesTags: ['Surveys'],
      async onQueryStarted(
        { questionId, ...patchRecipe },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = coreApi.util.updateQueryData(
          'getAnswerById',
          questionId,
          draft => {
            Object.assign(draft, patchRecipe)
          }
        )
        const cachePatch = dispatch(patchResult)
        queryFulfilled.catch(cachePatch.undo)
      },
    }),
    createProfile: builder.mutation<ProfileDto, ProfileCreateRequest>({
      query: profile => ({
        url: '/profiles',
        method: 'POST',
        body: profile,
      }),
      invalidatesTags: [
        'Answers',
        'Surveys',
        'EventRating',
        'Profile',
        'PreviousSurveys',
      ],
    }),
    getProfile: builder.query<ProfileDto, null>({
      query: () => `/profiles`,
      providesTags: ['Profile'],
    }),
    updateProfile: builder.mutation<ProfileDto, ProfileUpdateRequest>({
      query: profile => ({
        url: '/profiles',
        method: 'PATCH',
        body: profile,
      }),
      invalidatesTags: ['Profile'],
    }),
    createUser: builder.mutation<UserDto, UserCreateRequest>({
      query: user => ({
        url: '/users',
        method: 'POST',
        body: user,
      }),
      invalidatesTags: [
        'Answers',
        'Surveys',
        'PreviousSurveys',
        'EventRating',
        'Profile',
      ],
    }),
    deleteUser: builder.mutation<void, null>({
      query: user => ({
        url: '/users',
        method: 'DELETE',
      }),
      invalidatesTags: [
        'Answers',
        'EventRating',
        'PreviousSurveys',
        'Surveys',
        'Profile',
      ],
    }),
    validateEmail: builder.mutation<ValidateEmailDto, ValidateEmailRequest>({
      query: body => ({
        url: '/users/validate-email',
        method: 'POST',
        body: body,
      }),
    }),
    authenticate: builder.mutation<
      UserAuthenticateDto,
      UserAuthenticateRequest
    >({
      query: user => ({
        url: '/users/auth',
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['Answers', 'Surveys'],
    }),
    validateCode: builder.mutation<EventCodeDto, ValidateCodeRequest>({
      query: body => ({
        url: '/events/validate-code',
        method: 'POST',
        body: body,
      }),
    }),
    assignCode: builder.mutation<EventCodeDto, ValidateCodeRequest>({
      query: body => ({
        url: '/events/assign-code',
        method: 'POST',
        body: body,
      }),
    }),
    createFeedback: builder.mutation<FeedbackDto, FeedbackCreateRequest>({
      query: body => ({
        url: '/profiles/feedback',
        method: 'POST',
        body: body,
      }),
    }),
    createEventRating: builder.mutation<
      EventRatingDto,
      EventRatingCreateRequest
    >({
      query: body => ({
        url: '/events/rating',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['EventRating'],
    }),
    getEventRating: builder.query<EventRatingDto, number>({
      query: eventId => `/events/${eventId}/rating`,
      providesTags: ['EventRating'],
    }),
    resetPassword: builder.mutation<void, ResetPasswordRequest>({
      query: body => ({
        url: '/users/reset-password',
        method: 'POST',
        body,
      }),
    }),
    validateResetCode: builder.mutation<void, ValidateResetCodeRequest>({
      query: body => ({
        url: '/users/validate-reset-code',
        method: 'POST',
        body: body,
      }),
    }),
    changePassword: builder.mutation<void, ChangePasswordRequest>({
      query: body => ({
        url: '/users/change-password',
        method: 'POST',
        body,
      }),
    }),
    resetCache: builder.mutation<void, { timestamp: number }>({
      query: () => ({
        url: '/ping',
        method: 'GET',
      }),
      invalidatesTags: [
        'Answers',
        'EventRating',
        'PreviousSurveys',
        'Surveys',
        'Profile',
      ],
    }),
  }),
})

export const {
  useGetSurveysQuery,
  useGetPreviousSurveysQuery,
  useGetSurveyQuery,
  useGetQuestionsQuery,
  useCreateAnswerMutation,
  useGetAnswerByIdQuery,
  useCreateProfileMutation,
  useCreateUserMutation,
  useAuthenticateMutation,
  useValidateCodeMutation,
  useAssignCodeMutation,
  useCreateFeedbackMutation,
  useCreateEventRatingMutation,
  useGetEventRatingQuery,
  useGetProfileQuery,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useResetCacheMutation,
  useUpdateProfileMutation,
  useDeleteUserMutation,
  useValidateEmailMutation,
  useValidateResetCodeMutation,
} = coreApi
