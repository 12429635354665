import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { StyleSheet, TextInput } from 'react-native'
import { AnswerDto, QuestionDto } from '../../../src/types/coreApi-types'
import { theme } from '../../../theme/theme'
import Input from '../../../components/Input'

type Props = {
  question: QuestionDto
  answersState: string[]
  setAnswersState: Dispatch<SetStateAction<string[]>>
}

export default function NumberQuestion({
  question,
  answersState,
  setAnswersState,
}: Props) {
  const { colors } = theme

  return (
    <>
      <TextInput
        style={{ padding: 10, backgroundColor: colors['grey-button'] }}
        keyboardType="numeric"
        returnKeyType="done"
        onChangeText={text =>
          setAnswersState(value => {
            const newArr = [...value]
            newArr[0] = text.replace(/[^0-9]/g, '')
            return newArr
          })
        }
        value={answersState[0] ?? ''}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'black',
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: 5,
  },
})
