import { RouteProp, useLinkProps } from '@react-navigation/native'
import React, { ReactNode, useContext } from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import {
  HomeTabNavigationParamList,
  UnionNavigatorParamList,
} from '../types/NavigationTypes'
import ProfileNavigator from './ProfileNavigator'
import HomeNavigator from './HomeNavigator'
import { theme } from '../theme/theme'
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo'
import { HideTabBarContext } from '../contexts/HideTabBarContext'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons/faCalendarDays'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { t } from 'i18n-js'
import { LocaleContext } from '../contexts/LocaleContext'

function TabButton({
  to,
  action,
  children,
  style,
}: {
  to: To<UnionNavigatorParamList>
  action?: any
  children: ReactNode
  style?: StyleProp<ViewStyle>
}) {
  const { onPress, ...linkProps } = useLinkProps({ to, action })

  return (
    <View
      // @ts-ignore
      onClick={onPress}
      style={style}
      {...linkProps}
    >
      {children}
    </View>
  )
}

interface NavigationProps {}

const Tab = createBottomTabNavigator<HomeTabNavigationParamList>()

const HomeTabNavigation: React.FC<NavigationProps> = () => {
  const { colors } = theme

  const getTabTitle = (
    route: RouteProp<
      HomeTabNavigationParamList,
      keyof HomeTabNavigationParamList
    >
  ) => {
    switch (route.name) {
      case 'HomeNavigator':
        return t('events.title')
      case 'ProfileNavigator':
        return t('profile.title')
    }
  }

  const { hideTabBar } = useContext(HideTabBarContext)

  const { locale, setLocale } = useContext(LocaleContext)

  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        headerShown: false,
        title: getTabTitle(route),
        tabBarLabelStyle: {
          fontFamily: theme.fonts.medium,
        },
        tabBarActiveTintColor: colors.accent,
        tabBarButton: props => {
          let linkTo
          if (route.name === 'HomeNavigator') linkTo = '/home'
          else linkTo = '/profile'

          return <TouchableOpacity {...props} />
        },
        tabBarIcon: ({ focused, color, size }) => {
          return (
            <FontAwesomeIcon
              icon={route.name === 'HomeNavigator' ? faCalendarDays : faUser}
              size={size - 2}
              color={color}
              style={{ opacity: focused ? 1 : 0.6 }}
            />
          )
        },
      })}
    >
      <Tab.Screen
        name="HomeNavigator"
        component={HomeNavigator}
        options={({ route }) => ({
          tabBarStyle: (route => {
            if (hideTabBar) {
              return { display: 'none' }
            }
            return
          })(route),
        })}
      />
      <Tab.Screen name="ProfileNavigator" component={ProfileNavigator} />
    </Tab.Navigator>
  )
}

export default HomeTabNavigation
