import React from 'react'
import { useToast } from 'react-native-toast-notifications'
import { bindHooksToRTKErrorLogger } from '../redux/rtkQueryErrorLogger'

const RTKErrorLoggerProvider: React.FC<{
  children?: React.ReactNode[] | React.ReactNode
}> = ({ children }) => {
  const toast = useToast()

  bindHooksToRTKErrorLogger(toast)

  return <>{children}</>
}

export default RTKErrorLoggerProvider
