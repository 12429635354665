import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { theme } from '../theme/theme'

interface BadgeProps {
  children: React.ReactNode
}

const Badge: React.FC<BadgeProps> = ({ children }) => {
  return (
    <View style={styles.container}>
      <Text
        style={{
          color: '#fff',
          fontFamily: theme.fonts.semi,
          fontSize: 12,
        }}
      >
        {children}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 12,
    borderRadius: 100,
    backgroundColor: theme.colors.secondary,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default Badge
