import moment from 'moment'
import { Gender, QuestionDto } from '../src/types/coreApi-types'

export const validateAnswer = (
  question: QuestionDto,
  answers: string[],
  columnAnswers: string[]
) => {
  if (!answers || !answers[0]) return false

  // Check for duplicates
  if (
    new Set(answers).size !== answers.length ||
    new Set(columnAnswers).size !== columnAnswers.length
  )
    return false

  switch (question.questionType) {
    case 'MultipleChoice':
      if (question.minimum && question.minimum > answers.length) return false
      if (question.maximum && question.maximum < answers.length) return false
      return true

    case 'Text':
      if (question.maximum && question.maximum < answers[0].length) return false
      if (question.minimum && question.minimum > answers[0].length) return false
      return true

    case 'Number':
      if (!RegExp(/[0-9]*/g).test(answers[0])) return false
      if (question.maximum && question.maximum < Number(answers[0]))
        return false
      if (question.minimum && question.minimum > Number(answers[0]))
        return false

      return true

    case 'SingleChoice':
      if (answers.length !== 1) return false
      return true

    case 'Mood':
      if (question.minimum && question.minimum > answers.length) return false
      if (question.maximum && question.maximum < answers.length) return false
      return true

    case 'MultipleChoiceImage':
      if (question.minimum && question.minimum > answers.length) return false
      if (question.maximum && question.maximum < answers.length) return false
      return true

    case 'Ranking':
      if (question.minimum && question.minimum > answers.length) return false
      if (question.maximum && question.maximum < answers.length) return false
      return true

    case 'BatteryChoice':
      if (
        question.options.some(option => {
          const amount = answers.filter(answer =>
            answer.includes(option)
          ).length
          if (
            (question.minimum && amount < question.minimum) ||
            (question.maximum && amount > question.maximum)
          ) {
            return true
          } else if (!question.minimum && amount < 1) {
            return true
          }
          return false
        })
      )
        return false
      return true

    case 'BatterySlider':
      if (question.options.length !== answers.length) return false
      if (
        question.options.some(option => {
          const answer = answers.filter(answer => answer.includes(option))[0]
          if (!answer) return true
          if (!RegExp(/[0-9]*/g).test(answer)) return true
          if (question.maximum && question.maximum < Number(answer)) return true
          if (question.minimum && question.minimum > Number(answer)) return true

          return false
        })
      )
        return false
      return true
  }
}

export const validateUserSignup = (
  screen: number,
  fullName: string,
  email: string,
  password: string,
  confirmPassword: string,
  oldPassword?: string,
  update?: boolean
) => {
  switch (screen) {
    case 1:
      if (!fullName) return false
      return true
    case 2:
      if (!email || !RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(email))
        return false
      return true
    case 3:
      if (
        !password ||
        !RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/g).test(
          password
        ) ||
        !confirmPassword ||
        password !== confirmPassword ||
        (update && !oldPassword)
      )
        return false
      return true
    default:
      return true
  }
}

export const validateGuestSignup = (
  birthdate: string | undefined,
  gender: Gender | undefined,
  zipCode: string
) => {
  if (!birthdate || !birthdate.match(/\d{4}/g)) return false

  // 18 years or older
  if (
    new Date(
      new Date().getTime() - new Date(birthdate).getTime()
    ).getUTCFullYear() -
      1970 <
    18
  ) {
    return false
  }

  // No older than 100 years
  if (
    new Date(
      new Date().getTime() - new Date(birthdate).getTime()
    ).getUTCFullYear() -
      1970 >
    100
  ) {
    return false
  }

  if (zipCode && !zipCode.match(/\d{4}/g)) return false

  return true
}

export const validateResetPassword = (
  screen: number,
  email: string,
  emailCode: string,
  password: string,
  confirmPassword: string
) => {
  switch (screen) {
    case 1:
      if (!email || !RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(email))
        return false
      return true
    case 2:
      if (!emailCode) return false
      return true
    case 3:
      if (
        !password ||
        !RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/g).test(
          password
        ) ||
        !confirmPassword ||
        password !== confirmPassword
      )
        return false
      return true
    default:
      return true
  }
}
