import React, { Dispatch, SetStateAction } from 'react'
import { StyleSheet } from 'react-native'
import { QuestionDto } from '../../../src/types/coreApi-types'
import { theme } from '../../../theme/theme'
import ChoiceCard from '../components/ChoiceCard'
import PrimaryButton from '../../../components/PrimaryButton'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faRotate } from '@fortawesome/free-solid-svg-icons/faRotate'
import { t } from 'i18n-js'

type Props = {
  question: QuestionDto
  answersState: string[]
  setAnswersState: Dispatch<SetStateAction<string[]>>
}

export default function RankingQuestion({
  question,
  answersState,
  setAnswersState,
}: Props) {
  const { colors } = theme

  return (
    <>
      {question.options.map((option, index) => {
        const active = answersState.includes(option)
        const rank = answersState.findIndex(item => item === option)

        return (
          <ChoiceCard
            key={option}
            squareMarker
            option={option}
            active={active}
            rank={rank === -1 ? undefined : rank + 1}
            onPress={() => {
              if (active)
                return setAnswersState(value => [
                  ...value.filter(item => item !== option),
                ])
              if (question.maximum && answersState.length >= question.maximum)
                return
              setAnswersState(value => [...value, option])
            }}
          />
        )
      })}
      {answersState.length > 0 && (
        <PrimaryButton
          secondary
          rounded
          title={t('events.reset')}
          style={{ width: 100, alignSelf: 'center', marginTop: 20, height: 45 }}
          renderLeft={
            <FontAwesomeIcon
              icon={faRotate}
              size={16}
              style={{
                color: colors.accent,
              }}
            />
          }
          onPress={() => setAnswersState([])}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'black',
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: 5,
  },
})
