import * as React from 'react'
import { Text, TextProps } from 'react-native'
import { theme } from '../theme/theme'

interface Props extends TextProps {
  children?: any
}

export const Description = (props: Props) => (
  <Text
    {...props}
    style={[
      {
        fontFamily: theme.fonts.regular,
        fontSize: theme.textSizes.md,
        color: theme.colors.description,
      },
      props.style,
    ]}
  />
)

export const StrongDescription = (props: Props) => (
  <Text
    {...props}
    style={[
      {
        fontFamily: theme.fonts.semi,
        fontSize: theme.textSizes.md,
        color: theme.colors.description,
      },
      props.style,
    ]}
  />
)

export const Strong = (props: Props) => (
  <Text
    {...props}
    style={[
      {
        fontFamily: theme.fonts.semi,
        fontSize: theme.textSizes.md,
        color: theme.colors.accent,
        lineHeight: 23,
      },
      props.style,
    ]}
  />
)

export const Default = (props: Props) => (
  <Text
    {...props}
    style={[
      {
        fontFamily: theme.fonts.regular,
        fontSize: theme.textSizes.md,
        color: theme.colors.accent,
        lineHeight: 18,
      },
      props.style,
    ]}
  />
)

export const Header = (props: Props) => (
  <Text
    {...props}
    style={[
      {
        fontFamily: theme.fonts.medium,
        fontSize: theme.textSizes.xxl,
        color: theme.colors.accent,
      },
      props.style,
    ]}
  />
)

export const StrongHeader = (props: Props) => (
  <Text
    {...props}
    style={[
      {
        fontFamily: theme.fonts.semi,
        fontSize: theme.textSizes.xxl,
        color: theme.colors.accent,
        lineHeight: 25,
      },
      props.style,
    ]}
  />
)
