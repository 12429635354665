import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import { Description, StrongDescription } from '../../../components/Text'
import { theme } from '../../../theme/theme'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'

interface SurveyOverviewSectionProps {
  title: string
  description?: string
  toggleNumberOfDescriptionLines?: boolean
  hideDescription?: boolean
  showArrow?: boolean
  bottomBorder?: boolean
  active?: string
  setActive?: Dispatch<SetStateAction<string>>
}

const SurveyOverviewSection: React.FC<SurveyOverviewSectionProps> = ({
  title,
  description,
  toggleNumberOfDescriptionLines,
  hideDescription,
  showArrow,
  bottomBorder,
  active,
  setActive,
}) => {
  const { colors } = theme
  const [showDescription, setShowDescription] = useState(
    toggleNumberOfDescriptionLines ? false : true
  )

  const show = active ? active === title && showDescription : showDescription

  const hide = hideDescription && !show

  useEffect(() => {
    if (active !== title) {
      setShowDescription(false)
    }
  }, [active])

  return (
    <TouchableOpacity
      onPress={() => {
        if (toggleNumberOfDescriptionLines) {
          setShowDescription(!showDescription)
          if (setActive) {
            setActive(title)
          }
        }
      }}
      activeOpacity={toggleNumberOfDescriptionLines ? 0.8 : 1}
      style={[
        styles.container,
        bottomBorder
          ? {
              borderBottomWidth: 1,
              borderBottomColor: theme.colors['border-subtle'],
            }
          : {
              borderTopWidth: 1,
              borderTopColor: theme.colors['border-subtle'],
            },
      ]}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            color: theme.colors.accent,
            fontFamily: theme.fonts.semi,
            fontSize: 14,
          }}
        >
          {title}
        </Text>
        {showArrow && !show && (
          <FontAwesomeIcon
            size={10}
            style={{ color: colors.border, marginLeft: 8 }}
            icon={faChevronRight}
          />
        )}
      </View>
      {description && !hide && (
        <Description
          numberOfLines={show ? undefined : 8}
          style={{
            marginTop: 4,
          }}
        >
          {description}
        </Description>
      )}
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: -theme.pagePadding,
    padding: theme.pagePadding,
  },
})

export default SurveyOverviewSection
