import 'react-native-url-polyfill'
import {
  Button,
  KeyboardAvoidingView,
  NativeSyntheticEvent,
  Platform,
  Text,
  TextInput,
  TextInputFocusEventData,
  View,
} from 'react-native'
import { useAppLoader } from './containers/useAppLoader'
import * as Sentry from 'sentry-expo'
import { SENTRY_DSN } from './constants'
import Navigation from './containers/Navigation'
import { Provider, useDispatch, useSelector } from 'react-redux'
import store, { RootState } from './redux/store'
import * as Localization from 'expo-localization'
import i18n from 'i18n-js'
import en from './lang/en.json'
import da from './lang/da.json'
import * as SplashScreen from 'expo-splash-screen'
import { ToastProvider } from 'react-native-toast-notifications'
import RTKErrorLoggerProvider from './containers/RTKErrorLoggerProvider'
import moment from 'moment'
import 'moment/locale/da'
import {
  NavigationContainer,
  Route,
  useNavigationContainerRef,
} from '@react-navigation/native'
import * as Linking from 'expo-linking'
import { HideTabBarProvider } from './contexts/HideTabBarContext'
import { useEffect } from 'react'
import { setOnTemporaryRoute } from './redux/slices/sessionSlice'
import { enableScreens } from 'react-native-screens'
import { LocaleProvider } from './contexts/LocaleContext'
import { ClickOutsideProvider } from 'react-native-click-outside'

moment.locale('da')

// Set the key-value pairs for the different languages you want to support.
i18n.translations = {
  en,
  da,
}

Sentry.init({
  dsn: SENTRY_DSN,
  enableInExpoDevelopment: false,
  // debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  debug: false,
})

// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync()

const prefix = Linking.createURL('/')

export default function App() {
  return (
    <LocaleProvider>
      <ToastProvider offsetTop={20} style={{ borderRadius: 13 }}>
        <RTKErrorLoggerProvider>
          <Provider store={store}>
            <ClickOutsideProvider>
              <HideTabBarProvider>
                <AppLoader />
              </HideTabBarProvider>
            </ClickOutsideProvider>
          </Provider>
        </RTKErrorLoggerProvider>
      </ToastProvider>
    </LocaleProvider>
  )
}

const AppLoader: React.FC = () => {
  const { appIsReady, onLayoutRootView } = useAppLoader()
  const navigationRef = useNavigationContainerRef()

  useEffect(() => {
    // Fixes hiding tabbar on iOS
    if (Platform.OS === 'ios') {
      enableScreens(false)
    }
  }, [])

  const dispatch = useDispatch()
  const session = useSelector((state: RootState) => state.session)

  if (!appIsReady) return null

  const linking = {
    prefixes: [
      prefix,
      'https://app.sensimate.dk',
      'https://www.app.sensimate.dk',
    ],
    config: {
      screens: {
        Auth: {
          screens: {
            WelcomeScreen: '',
            LoginScreen: 'login',
            UserSignupScreen: '/signup/user',
            GuestSignupScreen: '/signup/guest',
            ResetPasswordScreen: 'reset-password',
          },
        },
        Home: {
          // HomeTabNavigation
          screens: {
            HomeNavigator: {
              screens: {
                HomeScreen: 'home',
                SurveyNavigator: {
                  path: 'survey',
                  screens: {
                    SurveyOverviewScreen: 'overview',
                    SurveyIntroductionScreen: 'introduction',
                    SurveyReadyScreen: 'ready',
                    SurveyCodeScreen: 'code',
                    SurveyFinishedScreen: 'finished',
                    TemporaryGuestSignupScreen: 'signup',
                  },
                },
              },
            },
            ProfileNavigator: {
              screens: {
                ProfileScreen: 'profile',
                EditProfileScreen: 'edit-profile',
                AboutScreen: 'about',
                FeedbackScreen: 'feedback',
                PreviousAnswersScreen: 'previous-answers',
                SupportScreen: 'support',
                GuestCompleteSignupScreen: 'guest-complete-signup',
              },
            },
          },
        },
      },
    },
  }

  function handleRouteChange(route?: Route<any, any>) {
    if (!route) return

    const timestamp = new Date().getTime()
    if (
      route.params?.temporary === 'true' ||
      route.params?.temporary === true
    ) {
      if (!session.onTemporaryRoute) {
        dispatch(setOnTemporaryRoute({ onTemporaryRoute: true }))
      }
    } else {
      if (session.onTemporaryRoute) {
        dispatch(setOnTemporaryRoute({ onTemporaryRoute: false }))
      }
    }

    const authNavigationScreenNames = Object.keys(
      linking.config.screens.Auth.screens
    )

    if (authNavigationScreenNames.includes(route.name)) {
      // In Auth Navigation
      if (session.active) {
        navigationRef.current?.reset({ routes: [{ name: 'Home' }] })
      }
    }
  }

  return (
    <View
      onLayout={onLayoutRootView}
      style={{
        flex: 1,
      }}
    >
      <NavigationContainer
        linking={linking}
        fallback={<Text>Loading...</Text>}
        ref={navigationRef}
        onReady={() => {
          const route = navigationRef?.getCurrentRoute()
          handleRouteChange(route)
        }}
        onStateChange={async e => {
          const route = navigationRef?.getCurrentRoute()
          handleRouteChange(route)
        }}
      >
        <Navigation />
      </NavigationContainer>
    </View>
  )
}
