import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import { QuestionDto } from '../../../src/types/coreApi-types'
import { theme } from '../../../theme/theme'
import ChoiceCard from '../components/ChoiceCard'
import AddOptionInput from '../components/AddOptionInput'
import { t } from 'i18n-js'

type Props = {
  question: QuestionDto
  answersState: string[]
  setAnswersState: Dispatch<SetStateAction<string[]>>
}

export default function SingleChoiceQuestion({
  question,
  answersState,
  setAnswersState,
}: Props) {
  const { colors } = theme

  const [additionalOptionText, setAdditionalOptionText] = useState('')
  const [additionalOptions, setAdditionalOptions] = useState<string[]>([])
  const [first, setFirst] = useState(true)

  const [error, setError] = useState('')

  const combinedAdditionalOptions = [
    ...additionalOptions,
    ...answersState.filter(
      item =>
        !question.options.includes(item) && !additionalOptions.includes(item)
    ),
  ]

  const combinedOptions = [...question.options, ...combinedAdditionalOptions]

  useEffect(() => {
    if (answersState.length > 0 && first) {
      setFirst(false)
      setAdditionalOptions(
        answersState.filter(item => !question.options.includes(item))
      )
    }
  }, [answersState])

  return (
    <>
      {combinedOptions.map((option, index) => {
        const active =
          Boolean(answersState) &&
          Boolean(answersState[0]) &&
          answersState[0] === option

        const additional = combinedAdditionalOptions.includes(option)
        return (
          <ChoiceCard
            key={option}
            option={option}
            active={active}
            onPress={() =>
              setAnswersState(value => {
                const newArr = [...value]
                newArr[0] = option
                return newArr
              })
            }
            additional={additional}
            onPressAdditional={() => {
              setAdditionalOptions(value => [
                ...value.filter(item => item !== option),
              ])
              if (answersState.includes(option)) {
                setAnswersState([])
              }
            }}
          />
        )
      })}
      {combinedAdditionalOptions.length < 5 && question.allowOther && (
        <AddOptionInput
          setAdditionalOptionText={setAdditionalOptionText}
          additionalOptionText={additionalOptionText}
          onBlur={() => {
            setError('')
            if (additionalOptionText) {
              if (combinedOptions.includes(additionalOptionText))
                return setError(t('validation.different-than-existing'))
              setAdditionalOptions(value => [...value, additionalOptionText])
              setAdditionalOptionText('')
            }
          }}
          error={error}
        />
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'black',
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: 5,
  },
})
