import React, { useContext, useEffect, useState } from 'react'
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  TouchableHighlight,
} from 'react-native'
import { useClickOutside } from 'react-native-click-outside'
import { theme } from '../theme/theme'
import { t } from 'i18n-js'
import { LocaleContext } from '../contexts/LocaleContext'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Svg, Path } from 'react-native-svg'

interface LanguagePickerProps {
  availableLangs?: string[]
  currentLang?: string
}

const LanguagePicker: React.FC<LanguagePickerProps> = ({
  availableLangs,
  currentLang,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const ref = useClickOutside<View>(() => setIsVisible(false))

  const { locale, setLocale } = useContext(LocaleContext)

  useEffect(() => {
    if (!currentLang) return
    if (currentLang !== locale) setLocale(currentLang)
  }, [currentLang])

  const languages = [
    {
      name: 'Dansk',
      icon: require('../assets/danish.png'),
      locale: 'da',
    },
    {
      name: 'English',
      icon: require('../assets/english.png'),
      locale: 'en',
    },
  ]

  return (
    <View
      style={{
        justifyContent: 'flex-end',
        flexDirection: 'row',
        position: 'relative',
        zIndex: 1,
      }}
      ref={ref}
    >
      <TouchableOpacity
        activeOpacity={0.8}
        onPress={e => {
          e.stopPropagation()
          e.preventDefault()
          if (isVisible) {
            setIsVisible(false)
          } else {
            setIsVisible(true)
          }
        }}
        style={{
          paddingHorizontal: 12,
          paddingVertical: 10,
          backgroundColor: theme.colors['border-subtle'],
          borderRadius: 20,
          flexDirection: 'row',
          alignItems: 'center',
          gap: 6,
        }}
      >
        <Image
          source={require('../assets/earth.png')}
          style={{ width: 16, height: 16 }}
        />
        <Text
          style={{
            color: theme.colors.accent,
            fontFamily: theme.fonts.semi,
            fontSize: 12,
          }}
        >
          {locale.toUpperCase()}
        </Text>
        <Image
          source={require('../assets/chevron.png')}
          style={{ width: 12, height: 12 }}
        />
      </TouchableOpacity>
      {isVisible && (
        <View
          style={{
            position: 'absolute',
            top: 48,
            right: 0,
            backgroundColor: '#F4F6F6',
            borderWidth: 1,
            borderColor: '#E0E2E2',
            borderRadius: 12,
            padding: 16,
            gap: 16,
            zIndex: 9999,
          }}
          ref={ref}
        >
          {languages
            .filter(item =>
              availableLangs ? availableLangs.includes(item.locale) : true
            )
            .map((item, i) => {
              return (
                <TouchableOpacity
                  activeOpacity={0.8}
                  onPress={() => {
                    setLocale(item.locale)
                    setIsVisible(false)
                  }}
                  key={i}
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: 180,
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 14,
                    }}
                  >
                    {/* <Image
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: 100,
                      backgroundColor: '#000',
                    }}
                    source={item.icon}
                  /> */}
                    <Text style={{ fontFamily: theme.fonts.medium }}>
                      {item.name}
                    </Text>
                  </View>
                  {locale === item.locale && (
                    <View>
                      <Image
                        source={require('../assets/check.png')}
                        style={{ width: 13, height: 9 }}
                      />
                      {/* <FontAwesomeIcon icon={faCheckCircle} /> */}
                    </View>
                  )}
                </TouchableOpacity>
              )
            })}
        </View>
      )}
    </View>
  )
}

export default LanguagePicker
