import React, { useContext, useLayoutEffect } from 'react'
import { SurveyNavigatorParamList } from '../types/NavigationTypes'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import SurveyReadyScreen from '../screens/Survey/SurveyReadyScreen'
import SurveyOverviewScreen from '../screens/Survey/SurveyOverviewScreen'
import SurveyIntroductionScreen from '../screens/Survey/SurveyIntroductionScreen'
import SurveyCodeScreen from '../screens/Survey/SurveyCodeScreen'
import SurveyFinishedScreen from '../screens/Survey/SurveyFinishedScreen'
import TemporaryGuestSignupScreen from '../screens/Auth/TemporaryGuestSignupScreen'
import {
  getFocusedRouteNameFromRoute,
  useNavigation,
  useRoute,
} from '@react-navigation/native'
import { HideTabBarContext } from '../contexts/HideTabBarContext'
import WelcomeScreen from '../screens/Auth/WelcomeScreen'
import GuestSignupScreen from '../screens/Auth/GuestSignupScreen'
import getCurrentProfileId from '../utils/getCurrentProfileId'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'

interface NavigationProps {}

const Stack = createNativeStackNavigator<SurveyNavigatorParamList>()

const SurveyNavigator: React.FC<NavigationProps> = () => {
  const navigation = useNavigation()
  const route = useRoute()

  const session = useSelector((state: RootState) => state.session)
  const profileId = getCurrentProfileId(session)

  const { hideTabBar, setHideTabBar } = useContext(HideTabBarContext)

  useLayoutEffect(() => {
    // QR Code flow tweak
    if (!profileId) {
      setHideTabBar(true)
      return
    }
    const routeName = getFocusedRouteNameFromRoute(route)

    const hideTabBarRouteNames = [
      'TemporaryGuestSignupScreen',
      'SurveyReadyScreen',
      'SurveyFinishedScreen',
      'SurveyIntroductionScreen',
      'GuestSignupScreen',
      'SurveyOverviewScreen',
      'SurveyCodeScreen',
    ]

    if (routeName && hideTabBarRouteNames.includes(routeName)) {
      setHideTabBar(true)
    } else if (hideTabBar) {
      setHideTabBar(false)
    }

    return () => {
      setHideTabBar(false)
    }
  }, [navigation, route, profileId])

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="SurveyOverviewScreen"
        component={SurveyOverviewScreen}
        options={{ title: 'Survey overview / SensiMate' }}
      />
      <Stack.Screen
        name="TemporaryGuestSignupScreen"
        component={TemporaryGuestSignupScreen}
        options={{ title: 'Guest profile / SensiMate' }}
      />
      <Stack.Screen
        name="SurveyReadyScreen"
        component={SurveyReadyScreen}
        options={{ title: 'Survey / SensiMate' }}
      />
      <Stack.Screen
        name="SurveyIntroductionScreen"
        component={SurveyIntroductionScreen}
        options={{ title: 'Survey introduction / SensiMate' }}
      />
      <Stack.Screen
        name="SurveyCodeScreen"
        component={SurveyCodeScreen}
        options={{ title: 'Survey code / SensiMate' }}
      />
      <Stack.Screen
        name="SurveyFinishedScreen"
        component={SurveyFinishedScreen}
        options={{ title: 'Survey finished / SensiMate' }}
      />
      <Stack.Screen
        name="GuestSignupScreen"
        component={GuestSignupScreen}
        options={{ title: 'Guest / SensiMate' }}
      />
    </Stack.Navigator>
  )
}

export default SurveyNavigator
