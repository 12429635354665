import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Description } from '../../../components/Text'
import { theme } from '../../../theme/theme'

interface SurveyStepProps {
  stepCount: number
  title: string
  active?: boolean
  ignoreLine?: boolean
}

const SurveyStep: React.FC<SurveyStepProps> = ({
  stepCount,
  title,
  active,
  ignoreLine,
}) => {
  return (
    <View style={styles.container}>
      <View>
        <Description
          style={{
            fontSize: theme.textSizes.sm,
            fontFamily: theme.fonts.semi,
          }}
        >
          STEP {stepCount}
        </Description>
        <Description
          style={{ fontSize: theme.textSizes.lg, color: theme.colors.accent }}
        >
          {title}
        </Description>
      </View>
      <View style={{ flexDirection: 'row', gap: 10 }}>
        {active && (
          <Description
            style={{
              fontSize: theme.textSizes.sm,
              color: theme.colors.secondary,
              marginTop: 5,
            }}
          >
            You are here
          </Description>
        )}

        <View style={{ flexDirection: 'column', alignItems: 'center' }}>
          <View
            style={{
              backgroundColor: active
                ? theme.colors.secondary
                : theme.colors.bg,
              width: 28,
              height: 28,
              borderRadius: 100,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              borderWidth: active ? 0 : 1,
              borderColor: theme.colors.border,
            }}
          >
            {active && (
              <View
                style={{
                  width: 8,
                  height: 8,
                  backgroundColor: theme.colors.bg,
                  borderRadius: 100,
                }}
              />
            )}
          </View>
          {!ignoreLine && (
            <View
              style={{
                width: 1,
                height: 44,
                backgroundColor: active
                  ? theme.colors.secondary
                  : theme.colors.border,
              }}
            />
          )}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

export default SurveyStep
