import React, { useEffect } from 'react'
import {
  NativeStackNavigationProp,
  createNativeStackNavigator,
} from '@react-navigation/native-stack'
import {
  AuthNavigationParamList,
  NavigationParamList,
} from '../types/NavigationTypes'
import WelcomeScreen from '../screens/Auth/WelcomeScreen'
import LoginScreen from '../screens/Auth/LoginScreen'
import GuestSignupScreen from '../screens/Auth/GuestSignupScreen'
import GuestCompleteSignupScreen from '../screens/Auth/GuestCompleteSignupScreen'
import UserSignupScreen from '../screens/Auth/UserSignupScreen'
import ResetPasswordScreen from '../screens/Auth/ResetPasswordScreen'
import SurveyOverviewScreen from '../screens/Survey/SurveyOverviewScreen'

const Stack = createNativeStackNavigator<AuthNavigationParamList>()

const AuthNavigation: React.FC = () => {
  return (
    <Stack.Navigator
      screenOptions={({ route }) => ({
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="WelcomeScreen"
        component={WelcomeScreen}
        options={{ title: 'Welcome / SensiMate' }}
      />
      <Stack.Screen
        name="LoginScreen"
        component={LoginScreen}
        options={{ title: 'Login / SensiMate' }}
      />
      <Stack.Screen
        name="UserSignupScreen"
        component={UserSignupScreen}
        options={{ title: 'Signup / SensiMate' }}
      />
      <Stack.Screen
        name="GuestSignupScreen"
        component={GuestSignupScreen}
        options={{ title: 'Guest / SensiMate' }}
      />
      <Stack.Screen
        name="ResetPasswordScreen"
        component={ResetPasswordScreen}
        options={{ title: 'Reset Password / SensiMate' }}
      />
      <Stack.Screen
        name="SurveyOverviewScreen"
        component={SurveyOverviewScreen}
        options={{ title: 'Survey overview / SensiMate' }}
      />
    </Stack.Navigator>
  )
}

export default AuthNavigation
