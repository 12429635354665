import React, { useContext, useEffect, useState } from 'react'
import {
  View,
  StyleSheet,
  TouchableOpacity,
  StatusBar,
  ActivityIndicator,
} from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { RouteProp } from '@react-navigation/native'
import { t } from 'i18n-js'
import { SurveyNavigatorParamList } from '../../types/NavigationTypes'
import PrimaryButton from '../../components/PrimaryButton'
import Page from '../../components/Page'
import { theme } from '../../theme/theme'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faHouse } from '@fortawesome/free-solid-svg-icons/faHouse'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons/faUserGroup'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck'
import { faStar } from '@fortawesome/free-regular-svg-icons/faStar'
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons/faStar'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { Default, Description, Header, Strong } from '../../components/Text'
import {
  useCreateEventRatingMutation,
  useGetEventRatingQuery,
  useGetSurveyQuery,
} from '../../redux/services/coreApi'
import { useDispatch, useSelector } from 'react-redux'
import { startSession } from '../../redux/slices/sessionSlice'
import { redirectToPage } from '../../utils/redirectToPage'
import { RootState } from '../../redux/store'
import { LocaleContext } from '../../contexts/LocaleContext'
import { HideTabBarContext } from '../../contexts/HideTabBarContext'

type SurveyFinishedScreenNavigationProps = NativeStackNavigationProp<
  SurveyNavigatorParamList,
  'SurveyFinishedScreen'
>
type SurveyFinishedScreenRouteProps = RouteProp<
  SurveyNavigatorParamList,
  'SurveyFinishedScreen'
>

type Props = {
  navigation: SurveyFinishedScreenNavigationProps
  route: SurveyFinishedScreenRouteProps
}

export default function SurveyFinishedScreen({ navigation, route }: Props) {
  const { eventId, surveyId } = route.params
  const { colors } = theme
  const { locale, setLocale } = useContext(LocaleContext)

  const { data: survey } = useGetSurveyQuery({ surveyId, locale })
  const { isLoading: isGettingEventRating, currentData: eventRating } =
    useGetEventRatingQuery(eventId, { refetchOnMountOrArgChange: true })

  const [createEventRating, { isLoading: isCreatingEventRating }] =
    useCreateEventRatingMutation()

  const [rating, setRating] = useState<number | null>()

  const isLoading = isGettingEventRating || isCreatingEventRating

  const dispatch = useDispatch()

  const session = useSelector((state: RootState) => state.session)

  const { hideTabBar, setHideTabBar } = useContext(HideTabBarContext)

  useEffect(() => {
    setRating(eventRating?.rating ?? null)
  }, [eventRating])

  return (
    <Page backButtonDisabled bg={colors.accent}>
      <StatusBar barStyle={'light-content'} backgroundColor={colors.accent} />
      <View style={styles.container}>
        <View
          style={{
            alignItems: 'center',
            alignContent: 'center',
            marginTop: 40,
          }}
        >
          <FontAwesomeIcon
            icon={faCircleCheck}
            size={40}
            style={{ color: colors['accent-text'] }}
          />
          <Header
            style={{
              textAlign: 'center',
              color: colors['accent-text'],
              marginTop: 10,
            }}
          >
            {t('events.completed.title')}
          </Header>
        </View>

        <View
          style={{
            alignItems: 'center',
            alignContent: 'center',
            gap: 6,
          }}
        >
          <Strong style={{ color: colors['accent-text'] }}>
            {t('events.completed.thank-you-title')}
          </Strong>
          <Description style={{ textAlign: 'center', color: '#A8AEB5' }}>
            {t('events.completed.thank-you-description')}
          </Description>
          <View style={{ width: '100%', marginTop: 30 }}>
            <View style={styles.barContainer}>
              <Default style={{ color: colors['accent-text'] }}>
                {t('events.completed.follow-us')}
              </Default>
              <View style={{ flexDirection: 'row' }}>
                <TouchableOpacity
                  activeOpacity={0.5}
                  onPress={() => {
                    redirectToPage(
                      'https://www.instagram.com/sensimate_dk',
                      'instagram://user?username=sensimate_dk'
                    )
                  }}
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size={22}
                    style={{ color: colors['accent-text'] }}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  activeOpacity={0.5}
                  onPress={() => {
                    redirectToPage(
                      'https://www.facebook.com/100452002419125',
                      'fb://page/100452002419125'
                    )
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    size={22}
                    style={{
                      color: colors['accent-text'],
                      marginLeft: 10,
                    }}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View
              style={[
                styles.barContainer,
                {
                  borderBottomWidth: 1,
                },
              ]}
            >
              <Default style={{ color: colors['accent-text'] }}>
                {t('events.completed.rate-survey')}
              </Default>
              <View
                style={{ flexDirection: 'row', gap: 4, alignItems: 'center' }}
              >
                {isLoading && (
                  <ActivityIndicator
                    size={'small'}
                    color={colors['accent-text']}
                  />
                )}

                {Array(5)
                  .fill(0)
                  .map((item, index) => {
                    const filled = index >= (rating ?? 0)
                    return (
                      <TouchableOpacity
                        key={index}
                        activeOpacity={0.8}
                        onPress={() => {
                          if (isLoading) return
                          createEventRating({
                            rating: index + 1,
                            eventId: eventId,
                          }).unwrap()
                        }}
                      >
                        <FontAwesomeIcon
                          icon={filled ? faStar : faStarSolid}
                          size={22}
                          style={{
                            color: !filled
                              ? colors.secondary
                              : colors['accent-text'],
                          }}
                        />
                      </TouchableOpacity>
                    )
                  })}
              </View>
            </View>
          </View>
        </View>

        <View style={{ gap: 6 }}>
          <PrimaryButton
            style={{
              borderStyle: 'solid',
              borderColor: colors['accent-text'],
              borderWidth: 1,
            }}
            title={t('events.let-my-friend-take-survey')}
            renderLeft={
              <FontAwesomeIcon
                icon={faUserGroup}
                size={15}
                style={{ color: colors['accent-text'] }}
              />
            }
            onPress={() => {
              if (survey) {
                if (session.onTemporaryRoute && !session.guestProfile) {
                  dispatch(
                    startSession({
                      temporaryGuestProfile: null,
                      guestProfile: session.temporaryGuestProfile,
                    })
                  )
                } else {
                  dispatch(
                    startSession({
                      temporaryGuestProfile: null,
                    })
                  )
                }

                if (survey.event.codeRequired) {
                  navigation.reset({
                    routes: [
                      {
                        name: 'SurveyCodeScreen',
                        params: {
                          surveyId,
                          temporary: true,
                        },
                      },
                    ],
                  })
                } else {
                  navigation.reset({
                    routes: [
                      {
                        name: 'TemporaryGuestSignupScreen',
                        params: {
                          surveyId,
                          eventId: survey.eventId,
                          temporary: true,
                        },
                      },
                    ],
                  })
                }
              }
            }}
          />
          <PrimaryButton
            tertiary
            title={t('events.completed.back-to-home')}
            renderLeft={
              <FontAwesomeIcon
                icon={faHouse}
                size={14}
                style={{ color: colors.accent, marginRight: 8 }}
              />
            }
            onPress={() => {
              if (session.onTemporaryRoute && !session.guestProfile) {
                dispatch(
                  startSession({
                    temporaryGuestProfile: null,
                    guestProfile: session.temporaryGuestProfile,
                  })
                )
              } else {
                dispatch(
                  startSession({
                    temporaryGuestProfile: null,
                  })
                )
              }

              navigation.reset({
                routes: [
                  {
                    name: 'HomeScreen',
                  },
                ],
              })
            }}
          />
        </View>
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  barContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 16,
    borderColor: '#374B61',
    borderStyle: 'solid',
    marginHorizontal: -16,
    paddingHorizontal: 16,
    borderTopWidth: 1,
  },
})
