import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import * as Localization from 'expo-localization'
import I18n, { t } from 'i18n-js'
import AsyncStorage from '@react-native-async-storage/async-storage'

export const LocaleContext = createContext({
  setLocale: (val: string) => {},
  locale: '',
})

export const LocaleProvider: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  const [locale, _setLocale] = useState(Localization.locale.substring(0, 2))

  useEffect(() => {
    async function setDefaultLocale() {
      const savedLocale = await AsyncStorage.getItem('@locale')
      const _locale = savedLocale ?? Localization.locale.substring(0, 2)
      I18n.locale = _locale
      setLocale(_locale)
    }

    setDefaultLocale()
  }, [])

  const setLocale = useCallback(async (locale: string) => {
    I18n.locale = locale
    _setLocale(locale)
    await AsyncStorage.setItem('@locale', locale)
  }, [])

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  )
}
