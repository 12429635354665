import React, { useContext, useEffect, useState } from 'react'
import { View, Text, Button, StyleSheet } from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { ProfileNavigatorParamList } from '../../types/NavigationTypes'
import { RouteProp } from '@react-navigation/native'
import PrimaryButton from '../../components/PrimaryButton'
import Page from '../../components/Page'
import Ionicons from '@expo/vector-icons/Ionicons'
import { theme } from '../../theme/theme'
import { Default, Description } from '../../components/Text'
import ProfileButton from './components/ProfileButton'
import { t } from 'i18n-js'
import {
  useDeleteUserMutation,
  useGetProfileQuery,
  useResetCacheMutation,
  useUpdateProfileMutation,
} from '../../redux/services/coreApi'
import GuestSignupFlow from '../Auth/components/GuestSignupFlow'
import { Gender } from '../../src/types/coreApi-types'
import UserSignupFlow from '../Auth/components/UserSignupFlow'
import { validateGuestSignup, validateUserSignup } from '../../utils/validation'
import { useDispatch, useSelector } from 'react-redux'
import { endSession } from '../../redux/slices/sessionSlice'
import { useToast } from 'react-native-toast-notifications'
import {
  errorToastOptions,
  successContrastToastOptions,
  successToastOptions,
} from '../../utils/toastOptions'
import alert from '../../utils/alertPolyfill'
import { RootState } from '../../redux/store'
import KeyboardAvoidingViewWeb from '../../components/KeyboardAvoidingViewWeb'
import { useKeyboardListener } from '../../hooks/useKeyboardListener'
import { genderToI18nKey } from '../../utils/genderToLangGender'

type EditProfileScreenNavigationProps = NativeStackNavigationProp<
  ProfileNavigatorParamList,
  'EditProfileScreen'
>
type EditProfileScreenRouteProps = RouteProp<
  ProfileNavigatorParamList,
  'EditProfileScreen'
>

type Props = {
  navigation: EditProfileScreenNavigationProps
  route: EditProfileScreenRouteProps
}

export default function EditProfileScreen({ navigation, route }: Props) {
  const { colors } = theme
  const toast = useToast()

  const [screen, setScreen] = useState<number>(0)

  const [birthdate, setBirthdate] = useState<string>()
  const [gender, setGender] = useState<Gender>()
  const [zipCode, setZipCode] = useState('')
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const dispatch = useDispatch()

  const { currentData: profile, isFetching: isGettingProfile } =
    useGetProfileQuery(null)

  const [updateProfile, { isLoading: isUpdatingProfile }] =
    useUpdateProfileMutation()
  const [deleteUser, { isLoading: isDeletingUser }] = useDeleteUserMutation()

  const [resetCache] = useResetCacheMutation()

  const { inputProps, keyboardOpen } = useKeyboardListener()

  const editProfileButtons = [
    {
      title: t('sign-up.gender'),
      onPress: () => {
        setScreen(1)
      },
      rightText: !profile?.gender
        ? ''
        : t(`${genderToI18nKey(profile?.gender)}`),
      disabled: false,
      sortOrder: 4,
    },
    {
      title: t('sign-up.birthyear'),
      onPress: () => {
        setScreen(1)
      },
      rightText: profile?.birthdate
        ? new Date(profile?.birthdate).getFullYear().toString()
        : null,
      disabled: false,
      sortOrder: 2,
    },
    {
      title: t('sign-up.post-code'),
      onPress: () => {
        setScreen(1)
      },
      rightText: profile?.zipCode,
      disabled: false,
      sortOrder: 5,
      warning: false,
    },
  ]

  const editUserButtons = profile?.user
    ? [
        {
          title: t('sign-up.name'),
          onPress: () => {
            setScreen(2)
          },
          rightText: profile.user?.fullName,
          disabled: false,
          sortOrder: 3,
        },
        {
          title: t('sign-up.email'),
          onPress: () => {},
          rightText: profile.user?.email,
          disabled: true,
          sortOrder: 1,
        },
        {
          title: t('edit-profile.change-password'),
          onPress: () => {
            setScreen(4)
          },
          rightText: '',
          disabled: false,
          sortOrder: 6,
        },
        {
          title: t('edit-profile.delete-account'),
          onPress: async () => {
            alert(
              t('edit-profile.delete-account'),
              t('edit-profile.delete-account-alert'),
              [
                {
                  text: t('general.cancel'),
                  onPress: () => {},
                  style: 'cancel',
                },
                {
                  text: t('general.delete'),
                  onPress: async () => {
                    await deleteUser(null).unwrap()
                    dispatch(endSession())
                    resetCache({ timestamp: new Date().getTime() })
                    toast.show(
                      t('edit-profile.delete-account-success'),
                      successContrastToastOptions
                    )
                    // @ts-ignore
                    navigation.replace('Auth', { screen: 'WelcomeScreen' })
                  },
                  style: 'destructive',
                },
              ]
            )
          },
          rightText: '',
          disabled: false,
          sortOrder: 7,
          warning: true,
        },
      ]
    : []

  if (profile?.user) editProfileButtons.push(...editUserButtons)

  useEffect(() => {
    if (profile) {
      setGender(profile.gender || undefined)
      setBirthdate(new Date(profile?.birthdate).getFullYear().toString())
      if (profile.zipCode) {
        setZipCode(profile.zipCode)
      }
      if (profile.user) {
        setFullName(profile.user.fullName)
        setEmail(profile.user.email)
      }
    }
  }, [profile, screen])

  return (
    <Page
      title={screen > 0 ? undefined : t('profile.account-and-profile')}
      loading={isGettingProfile}
      overwriteBackButton={
        screen > 0
          ? () => {
              setScreen(0)
            }
          : undefined
      }
    >
      <>
        {!screen && (
          <View style={styles.container}>
            <View>
              {editProfileButtons
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((item, index) => (
                  <ProfileButton
                    key={item.title}
                    title={item.title}
                    onPress={item.onPress}
                    rightText={item.rightText}
                    disabled={item.disabled}
                    warning={item.warning}
                  />
                ))}
            </View>
            <PrimaryButton
              style={{ marginLeft: 16, marginRight: 16 }}
              title={t('profile.log-out')}
              onPress={() => {
                dispatch(endSession())
                resetCache({ timestamp: new Date().getTime() })
                // @ts-ignore
                navigation.replace('Auth', { screen: 'WelcomeScreen' })
              }}
            />
          </View>
        )}
        {screen > 0 && (
          <View style={{ flex: 1, justifyContent: 'space-between' }}>
            <View>
              {screen === 1 && (
                <GuestSignupFlow
                  update
                  birthdate={birthdate}
                  setBirthDate={setBirthdate}
                  gender={gender}
                  setGender={setGender}
                  zipCode={zipCode}
                  setZipCode={setZipCode}
                  keyboardListenerInputProps={inputProps}
                />
              )}
              <UserSignupFlow
                update
                fullName={fullName}
                setFullName={setFullName}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                oldPassword={oldPassword}
                setOldPassword={setOldPassword}
                screen={screen - 1}
                keyboardListenerInputProps={inputProps}
              />
            </View>
            <KeyboardAvoidingViewWeb keyboardOpen={keyboardOpen} padding={-50}>
              <PrimaryButton
                disabled={
                  !birthdate ||
                  !validateGuestSignup(birthdate, gender, zipCode) ||
                  !validateUserSignup(
                    screen - 1,
                    fullName,
                    email,
                    password,
                    confirmPassword,
                    oldPassword,
                    true
                  ) ||
                  isUpdatingProfile
                }
                loading={isUpdatingProfile}
                title={t('general.save')}
                onPress={() => {
                  updateProfile({
                    fullName: fullName ? fullName : undefined,
                    gender: gender ? gender : undefined,
                    birthdate: birthdate ? birthdate : undefined,
                    newPassword: password ? password : undefined,
                    oldPassword: oldPassword ? oldPassword : undefined,
                    zipCode: zipCode ? zipCode : undefined,
                  })
                    .unwrap()
                    .then(() => {
                      toast.show(
                        t('success.account-updated'),
                        successToastOptions
                      )
                      setScreen(0)
                    })
                    .catch(() => {
                      toast.show(
                        t('validation.something-went-wrong'),
                        errorToastOptions
                      )
                      setScreen(0)
                    })
                }}
              />
            </KeyboardAvoidingViewWeb>
          </View>
        )}
      </>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    flex: 1,
    marginHorizontal: -16,
  },
})
