import React from 'react'
import { View, StyleSheet } from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { ProfileNavigatorParamList } from '../../types/NavigationTypes'
import { RouteProp } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import Page from '../../components/Page'
import { theme } from '../../theme/theme'
import ProfileButton from './components/ProfileButton'
import { Linking } from 'react-native'
import { t } from 'i18n-js'

type SupportScreenNavigationProps = NativeStackNavigationProp<
  ProfileNavigatorParamList,
  'SupportScreen'
>
type SupportScreenRouteProps = RouteProp<
  ProfileNavigatorParamList,
  'SupportScreen'
>

type Props = {
  navigation: SupportScreenNavigationProps
  route: SupportScreenRouteProps
}

export default function SupportScreen({ navigation, route }: Props) {
  const session = useSelector((state: RootState) => state.session)

  const { colors } = theme

  const supportButtons = [
    {
      title: t('help.faq.title'),
      onPress: () => navigation.navigate('FAQScreen'),
    },
    {
      title: t('help.email-us'),
      onPress: () => Linking.openURL('mailto:hello@sensimate.dk'),
    },
    {
      title: t('help.give-us-feedback'),
      onPress: () => navigation.navigate('FeedbackScreen'),
    },
  ]

  return (
    <Page title={t('help.title')}>
      <View style={styles.container}>
        <View>
          {supportButtons.map((item, index) => (
            <ProfileButton
              key={item.title}
              title={item.title}
              onPress={item.onPress}
            />
          ))}
        </View>
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    flex: 1,
    marginHorizontal: -16,
  },
})
