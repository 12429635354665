import React, { useState } from 'react'
import { View, StyleSheet, Text, Button, Alert } from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { AuthNavigationParamList } from '../../types/NavigationTypes'
import { Link, RouteProp } from '@react-navigation/native'
import { useDispatch } from 'react-redux'
import { startSession } from '../../redux/slices/sessionSlice'
import Input from '../../components/Input'
import Page from '../../components/Page'
import PrimaryButton from '../../components/PrimaryButton'
import { useAuthenticateMutation } from '../../redux/services/coreApi'
import LinkButton from '../../components/LinkButton'
import QuestionHeader from '../Survey/components/QuestionHeader'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { useToast } from 'react-native-toast-notifications'
import { errorToastOptions } from '../../utils/toastOptions'
import { t } from 'i18n-js'

type LoginScreenNavigationProps = NativeStackNavigationProp<
  AuthNavigationParamList,
  'LoginScreen'
>
type LoginScreenRouteProps = RouteProp<AuthNavigationParamList, 'LoginScreen'>

type Props = {
  navigation: LoginScreenNavigationProps
  route: LoginScreenRouteProps
}

export default function LoginScreen({ navigation, route }: Props) {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const toast = useToast()

  async function signInWithEmail() {
    await authenticate({ email, password })
      .unwrap()
      .then(res => {
        dispatch(
          startSession({
            token: res.token,
            refreshToken: res.refreshToken,
          })
        )

        navigation.navigate('Home')
      })
      .catch((error: FetchBaseQueryError | SerializedError) => {
        if ('data' in error && error.status === 400) {
          toast.show(
            t('validation.invalid-email-or-password'),
            errorToastOptions
          )
        }
      })
  }

  const [authenticate, { isLoading: isAuthenticating }] =
    useAuthenticateMutation()

  return (
    <Page>
      <View style={styles.container}>
        <QuestionHeader question={t('welcome.login')} />
        <View style={[styles.verticallySpaced]}>
          <Input
            onChangeText={text => setEmail(text)}
            value={email}
            placeholder={t('sign-up.email')}
            autoCapitalize={'none'}
            keyboard="email-address"
          />
          <Input
            onChangeText={text => setPassword(text)}
            value={password}
            secure
            placeholder={t('sign-up.password')}
            autoCapitalize={'none'}
          />
        </View>
        <View style={[styles.verticallySpaced, { paddingTop: 20 }]}>
          <PrimaryButton
            title={t('general.continue')}
            disabled={isAuthenticating}
            loading={isAuthenticating}
            onPress={() => {
              signInWithEmail()
            }}
          />
          <LinkButton
            to={{ screen: 'UserSignupScreen' }}
            tertiary
            title={t('sign-up.sign-up')}
          />
          <LinkButton
            to={{ screen: 'ResetPasswordScreen' }}
            tertiary
            title={t('sign-up.forgot-password')}
          />
        </View>
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
  },
  verticallySpaced: {
    gap: 6,
  },
})
