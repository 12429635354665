import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { ProfileNavigatorParamList } from '../../types/NavigationTypes'
import { RouteProp } from '@react-navigation/native'
import Page from '../../components/Page'
import { theme } from '../../theme/theme'
import SurveyOverviewSection from '../Survey/components/SurveyOverviewSection'
import { t } from 'i18n-js'

type FAQScreenNavigationProps = NativeStackNavigationProp<
  ProfileNavigatorParamList,
  'FAQScreen'
>
type FAQScreenRouteProps = RouteProp<ProfileNavigatorParamList, 'FAQScreen'>

type Props = {
  navigation: FAQScreenNavigationProps
  route: FAQScreenRouteProps
}

export default function FAQScreen({ navigation, route }: Props) {
  const { colors } = theme

  const [active, setActive] = useState('')

  const faqs = [
    {
      question: t('help.faq.1.q'),
      answer: t('help.faq.1.a'),
    },
    {
      question: t('help.faq.2.q'),
      answer: t('help.faq.2.a'),
    },
    {
      question: t('help.faq.3.q'),
      answer: t('help.faq.3.a'),
    },
    {
      question: t('help.faq.4.q'),
      answer: t('help.faq.4.a'),
    },
    {
      question: t('help.faq.5.q'),
      answer: t('help.faq.5.a'),
    },
    {
      question: t('help.faq.6.q'),
      answer: t('help.faq.6.a'),
    },
    {
      question: t('help.faq.7.q'),
      answer: t('help.faq.7.a'),
    },
    {
      question: t('help.faq.8.q'),
      answer: t('help.faq.8.a'),
    },
    {
      question: t('help.faq.9.q'),
      answer: t('help.faq.9.a'),
    },
  ]

  return (
    <Page title={t('help.faqs')}>
      <View style={styles.container}>
        <View>
          {faqs.map((item, index) => (
            <SurveyOverviewSection
              key={item.question}
              title={item.question}
              description={item.answer}
              toggleNumberOfDescriptionLines
              hideDescription
              showArrow
              bottomBorder
              active={active}
              setActive={setActive}
            />
          ))}
        </View>
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    flex: 1,
  },
})
