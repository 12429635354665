import React, { Dispatch, SetStateAction, useState } from 'react'
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native'
import { QuestionDto } from '../../../src/types/coreApi-types'
import { theme } from '../../../theme/theme'
import Input from '../../../components/Input'

type Props = {
  question: QuestionDto
  answersState: string[]
  setAnswersState: Dispatch<SetStateAction<string[]>>
}

export default function MoodQuestion({
  question,
  answersState,
  setAnswersState,
}: Props) {
  const { colors } = theme

  return (
    <>
      {question.options?.map((option, index) => {
        const active =
          answersState && answersState[0] && answersState[0] === option
        return (
          <TouchableOpacity
            key={option}
            style={{
              padding: 1,
              width: 50,
              height: 50,
              borderRadius: 25,
              borderColor: active ? colors.accent : colors['grey-button'],
              borderStyle: 'solid',
              borderWidth: 2,
            }}
            onPress={() =>
              setAnswersState(value => {
                const newArr = [...value]
                newArr[0] = option
                return newArr
              })
            }
          >
            <Image
              style={styles.image}
              resizeMode="center"
              source={{
                uri: option,
              }}
            />
          </TouchableOpacity>
        )
      })}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'black',
  },
  image: {
    width: 45,
    height: 45,
    borderRadius: 25,
    flex: 1,
  },
})
