import React, { Dispatch, SetStateAction } from 'react'
import { View, StyleSheet } from 'react-native'
import { QuestionDto } from '../../../src/types/coreApi-types'
import { theme } from '../../../theme/theme'
import PrimaryButton from '../../../components/PrimaryButton'
import { Strong } from '../../../components/Text'
import { t } from 'i18n-js'

type Props = {
  question: QuestionDto
  answersState: string[]
  setAnswersState: Dispatch<SetStateAction<string[]>>
  columnAnswersState: string[]
  setColumnAnswersState: Dispatch<SetStateAction<string[]>>
}

export default function BatteryChoiceQuestion({
  question,
  answersState,
  setAnswersState,
  columnAnswersState,
  setColumnAnswersState,
}: Props) {
  const { colors } = theme

  const setAnswer = (answer: string, columnAnswer: string, active: boolean) => {
    const answerWithKey = answer + ' KEY:' + columnAnswer
    const columnAnswerWithKey = columnAnswer + ' KEY:' + answer

    if (active) {
      setColumnAnswersState(value => [
        ...value.filter(item => item !== columnAnswerWithKey),
      ])
      setAnswersState(value => [
        ...value.filter(item => item !== answerWithKey),
      ])
    } else {
      if ((question.maximum && question.maximum === 1) || !question.maximum) {
        setColumnAnswersState(value => [
          ...value.filter(item => !item.includes('KEY:' + answer)),
          columnAnswerWithKey,
        ])
        setAnswersState(value => [
          ...value.filter(item => !item.includes(answer + ' KEY:')),
          answerWithKey,
        ])
        return
      }
      if (
        question.maximum &&
        columnAnswersState.filter(item => item.includes('KEY:' + answer))
          .length >= question.maximum
      )
        return
      setColumnAnswersState(value => [...value, columnAnswerWithKey])
      setAnswersState(value => [...value, answerWithKey])
    }
  }

  return (
    <View>
      {question.options.map((option, index) => {
        return (
          <View key={option}>
            <Strong>{option}</Strong>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: 6,
                marginBottom: index + 1 !== question.options.length ? 35 : 0,
                marginTop: 4,
              }}
            >
              {question.columnOptions.map((columnOption, i) => {
                const active =
                  columnAnswersState.includes(
                    columnOption + ' KEY:' + option
                  ) && answersState.includes(option + ' KEY:' + columnOption)
                return (
                  <PrimaryButton
                    style={{ flex: 1, height: 40 }}
                    key={columnOption}
                    title={columnOption}
                    secondary={!active}
                    textStyle={{
                      fontFamily: theme.fonts.regular,
                      fontSize: theme.textSizes.sm,
                    }}
                    onPress={() => {
                      setAnswer(option, columnOption, active)
                    }}
                  />
                )
              })}
            </View>
          </View>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'black',
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: 5,
  },
})
