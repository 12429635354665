import React, { useState } from 'react'
import { TouchableOpacity, View, Text } from 'react-native'
import { theme } from '../../../theme/theme'
import { Ionicons } from '@expo/vector-icons'
import PrimaryButton from '../../../components/PrimaryButton'
import { t } from 'i18n-js'

interface AllergicCheckProps {
  onPress: () => void
}

const AllergicCheck: React.FC<AllergicCheckProps> = ({ onPress }) => {
  const [isAllergic, setIsAllergic] = useState<boolean>(true)

  return (
    <View
      style={{
        borderTopColor: theme.colors['border-subtle'],
        borderTopWidth: 1,
        padding: theme.pagePadding,
        marginHorizontal: -theme.pagePadding,
        flexDirection: 'column',
        gap: 16,
        paddingBottom: -theme.pagePadding,
      }}
    >
      <TouchableOpacity
        activeOpacity={0.5}
        onPress={() => setIsAllergic(!isAllergic)}
        style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}
      >
        <View
          style={{
            height: 24,
            width: 24,
            borderRadius: 6,
            borderColor: theme.colors.accent,
            borderWidth: 1.5,
            backgroundColor: isAllergic ? theme.colors.bg : theme.colors.accent,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Ionicons name="checkmark" size={14} color="#fff" />
        </View>
        <Text
          style={{
            fontFamily: theme.fonts.semi,
            color: theme.colors.accent,
          }}
        >
          {t('events.i-am-not-allergic')}
        </Text>
      </TouchableOpacity>
      <PrimaryButton
        title={t('events.start-survey')}
        onPress={onPress}
        disabled={isAllergic}
      />
    </View>
  )
}

export default AllergicCheck
