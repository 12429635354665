import React, { useContext, useEffect, useState } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { RouteProp } from '@react-navigation/native'
import Input from '../../components/Input'
import {
  HomeNavigatorParamList,
  SurveyNavigatorParamList,
} from '../../types/NavigationTypes'
import PrimaryButton from '../../components/PrimaryButton'
import { Image } from 'expo-image'
import Page from '../../components/Page'
import { theme } from '../../theme/theme'
import { useDispatch, useSelector } from 'react-redux'
import { startSession } from '../../redux/slices/sessionSlice'
import { useGetSurveyQuery } from '../../redux/services/coreApi'
import {
  Description,
  Header,
  Strong,
  StrongDescription,
} from '../../components/Text'
import SurveyStep from './components/SurveyStep'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons/faUserGroup'
import AllergicCheck from './components/AllergicCheck'
import SurveyOverviewSection from './components/SurveyOverviewSection'
import { RootState } from '../../redux/store'
import { LocaleContext } from '../../contexts/LocaleContext'

type SurveyIntroductionScreenNavigationProps = NativeStackNavigationProp<
  SurveyNavigatorParamList,
  'SurveyIntroductionScreen'
>
type SurveyIntroductionScreenRouteProps = RouteProp<
  SurveyNavigatorParamList,
  'SurveyIntroductionScreen'
>

type Props = {
  navigation: SurveyIntroductionScreenNavigationProps
  route: SurveyIntroductionScreenRouteProps
}

export default function SurveyIntroductionScreen({ navigation, route }: Props) {
  const dispatch = useDispatch()

  const { surveyId } = route.params
  const session = useSelector((state: RootState) => state.session)
  const temporary = session.onTemporaryRoute
  const { locale, setLocale } = useContext(LocaleContext)
  const {
    data: survey,
    isFetching,
    error,
  } = useGetSurveyQuery({ surveyId, locale })
  const { colors } = theme

  const steps = ['Receive sample']
    .concat(survey?.event.codeRequired ? ['Enter code from sample'] : [])
    .concat('Taste and answer questions')

  useEffect(() => {
    if (error && 'data' in error && error.status === 404) {
      navigation.navigate('HomeScreen')
    }
  }, [error])

  return (
    <Page loading={!survey || isFetching}>
      <View style={styles.container}>
        <View>
          <View style={styles.titleContainer}>
            <Strong
              style={{
                color: theme.colors.secondary,
                fontSize: theme.textSizes.sm,
                fontFamily: theme.fonts.semi,
              }}
            >
              HOW IT WORKS
            </Strong>
            <Header style={{ fontSize: theme.textSizes.xxxl, marginTop: 4 }}>
              Introduction to survey
            </Header>
          </View>

          {steps.map((item, i) => {
            return (
              <SurveyStep
                key={i}
                stepCount={i + 1}
                title={item}
                active={i === 0}
                ignoreLine={i + 1 === steps.length}
              />
            )
          })}

          {survey && (
            <View style={{ marginTop: 30 }}>
              <SurveyOverviewSection
                title={
                  survey.allergens.length > 0
                    ? 'Allergens'
                    : 'Contains no allergens'
                }
                description={survey.allergens}
              />
            </View>
          )}
        </View>
        <AllergicCheck
          onPress={() => {
            if (!survey) return

            if (temporary) {
              if (survey.event.codeRequired) {
                navigation.navigate('SurveyCodeScreen', {
                  surveyId,
                  temporary: temporary,
                })
              } else {
                navigation.navigate('TemporaryGuestSignupScreen', {
                  surveyId,
                  eventId: survey.eventId,
                  temporary: temporary,
                })
              }
            } else {
              if (
                survey.event.codeRequired &&
                survey.event.eventCodes.length === 0
              ) {
                // Go to code if Code is required
                navigation.navigate('SurveyCodeScreen', {
                  surveyId,
                  temporary,
                })
              } else {
                // If no code is required, go to Survey screen
                navigation.navigate('SurveyReadyScreen', {
                  surveyId,
                  temporary,
                })
              }
            }
          }}
        />
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  titleContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 60,
  },
})
