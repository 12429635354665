import React from 'react'
import { View, StyleSheet, StatusBar } from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { AuthNavigationParamList } from '../../types/NavigationTypes'
import { RouteProp } from '@react-navigation/native'
import { theme } from '../../theme/theme'
import { startSession } from '../../redux/slices/sessionSlice'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from '../../components/PrimaryButton'
import Page from '../../components/Page'
import { RootState } from '../../redux/store'
import LinkButton from '../../components/LinkButton'
import { Image } from 'expo-image'
import { t } from 'i18n-js'
import LanguagePicker from '../../components/LanguagePicker'

type WelcomeScreenNavigationProps = NativeStackNavigationProp<
  AuthNavigationParamList,
  'WelcomeScreen'
>
type WelcomeScreenRouteProps = RouteProp<
  AuthNavigationParamList,
  'WelcomeScreen'
>

type Props = {
  navigation: WelcomeScreenNavigationProps
  route: WelcomeScreenRouteProps
}

export default function WelcomeScreen({ navigation, route }: Props) {
  const dispatch = useDispatch()
  const session = useSelector((state: RootState) => state.session)

  const { colors } = theme

  return (
    <Page
      backButtonDisabled
      bg="black"
      bgImage={
        <Image
          source={require('../../assets/background.png')}
          style={{ flex: 1, opacity: 0.4 }}
        />
      }
    >
      <StatusBar barStyle={'light-content'} backgroundColor={colors.accent} />
      <LanguagePicker />
      <View style={styles.container}>
        <View
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 90,
            marginBottom: 0,
          }}
        >
          <Image
            source={require('../../assets/welcome-logo.png')}
            style={{ width: 238, height: 200 }}
          />
        </View>
        <View style={{ gap: 6, paddingBottom: 24 }}>
          {session.guestProfile && session.guestProfile.id ? (
            <PrimaryButton
              secondary
              title={t('welcome.continue-without-account')}
              onPress={() => {
                dispatch(
                  startSession({
                    guestProfile: session.guestProfile,
                    token: null,
                    refreshToken: null,
                  })
                )
                navigation.navigate('Home')
              }}
            />
          ) : (
            <LinkButton
              secondary
              title={t('welcome.continue-without-account')}
              to={{ screen: 'GuestSignupScreen' }}
            />
          )}
          <LinkButton
            transparent
            style={{
              borderColor: 'white',
              borderWidth: 1,
              borderStyle: 'solid',
            }}
            title={t('welcome.login')}
            to={{ screen: 'LoginScreen' }}
          />
          <LinkButton
            transparent
            title={t('welcome.not-a-user')}
            to={{ screen: 'UserSignupScreen' }}
          />
        </View>
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  text: {
    marginTop: -110,
    fontSize: 24,
    textAlign: 'center',
    alignSelf: 'center',
    color: theme.colors.bg,
    width: 250,
  },
})
