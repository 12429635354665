import React, { useState } from 'react'
import { View, StyleSheet, Text, Button, Alert } from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { ProfileNavigatorParamList } from '../../types/NavigationTypes'
import type { RouteProp } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { startSession } from '../../redux/slices/sessionSlice'
import Page from '../../components/Page'
import PrimaryButton from '../../components/PrimaryButton'
import {
  useCreateUserMutation,
  useValidateEmailMutation,
} from '../../redux/services/coreApi'
import { RootState } from '../../redux/store'
import { validateUserSignup } from '../../utils/validation'
import UserSignupFlow from './components/UserSignupFlow'
import KeyboardAvoidingViewWeb from '../../components/KeyboardAvoidingViewWeb'
import { useKeyboardListener } from '../../hooks/useKeyboardListener'
import { t } from 'i18n-js'

type GuestCompleteSignupScreenNavigationProps = NativeStackNavigationProp<
  ProfileNavigatorParamList,
  'GuestCompleteSignupScreen'
>
type GuestCompleteSignupScreenRouteProps = RouteProp<
  ProfileNavigatorParamList,
  'GuestCompleteSignupScreen'
>

type Props = {
  navigation: GuestCompleteSignupScreenNavigationProps
  route: GuestCompleteSignupScreenRouteProps
}

export default function GuestCompleteSignupScreen({
  navigation,
  route,
}: Props) {
  const dispatch = useDispatch()
  const session = useSelector((state: RootState) => state.session)
  const [validateEmail, { isLoading: isValidatingEmail }] =
    useValidateEmailMutation()

  const [errorMessage, setErrorMessage] = useState('')

  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [screen, setScreen] = useState(1)
  const screens = 3

  const [createUser, { isLoading: isCreatingUser }] = useCreateUserMutation()

  const { inputProps, keyboardOpen } = useKeyboardListener()

  async function completeSignup() {
    if (!session.guestProfile) return
    await createUser({
      fullName,
      email,
      password,
      deviceId: session.guestProfile.id,
    })
      .unwrap()
      .then(res => {
        dispatch(
          startSession({
            token: res.token,
            refreshToken: res.refreshToken,
            guestProfile: null,
          })
        )
        navigation.navigate('ProfileScreen')
      })
  }

  return (
    <Page
      screens={screens}
      currentScreen={screen}
      screensText={t('general.step')}
      overwriteBackButton={
        screen === 1
          ? undefined
          : () => {
              setScreen(value => value - 1)
            }
      }
    >
      <View style={styles.container}>
        <View>
          <UserSignupFlow
            fullName={fullName}
            setFullName={setFullName}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            screen={screen}
            errorMessage={errorMessage}
            keyboardListenerInputProps={inputProps}
          />
        </View>

        <KeyboardAvoidingViewWeb keyboardOpen={keyboardOpen} padding={-50}>
          <PrimaryButton
            title={t('general.continue')}
            disabled={
              isCreatingUser ||
              !validateUserSignup(
                screen,
                fullName,
                email,
                password,
                confirmPassword
              )
            }
            loading={isCreatingUser || isValidatingEmail}
            onPress={async () => {
              setErrorMessage('')
              if (screen === 2) {
                const res = await validateEmail({ email: email }).unwrap()
                if (res.emailInUse) {
                  setErrorMessage(t('validation.email-already-in-use'))
                  return
                }
              }
              if (screen !== screens) return setScreen(value => value + 1)
              completeSignup()
            }}
          />
        </KeyboardAvoidingViewWeb>
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 40,
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
})
