const textSizes = {
  xs: 10,
  sm: 12,
  md: 14,
  lg: 16,
  xl: 18,
  xxl: 20,
  xxxl: 24,
  xxxxl: 28,
}

const fonts = {
  light: 'OpenSans-Regular',
  regular: 'OpenSans-Regular',
  medium: 'OpenSans-Medium',
  semi: 'OpenSans-SemiBold',
  bold: 'OpenSans-Bold',
}

const colors = {
  accent: '#263545',
  'accent-border': '#374B61',
  'accent-description': 'rgba(255, 255, 255, 0.6)',
  'accent-tint': '#EDF0F2',
  description: '#7D868F',
  secondary: '#BA8E73',
  'sky-blue': '#6580A5',
  'bottle-green': '#365334',
  plum: '#573A52',
  'curry-yellow': '#CAAE67',
  border: 'rgba(38, 53, 69, 0.2)',
  'border-subtle': '#F2F3F4',
  warning: '#CB4848',
  bg: '#FFFFFF',
  'accent-text': '#FFFFFF',
  'grey-button': '#F5F8FA',
}

const pagePadding = 16

export const theme = { textSizes, fonts, colors, pagePadding }
