import { useState, useEffect, useCallback } from 'react'
import * as SplashScreen from 'expo-splash-screen'
import { useDispatch } from 'react-redux'
import {
  getStateFromLocalStorage,
  startSession,
} from '../redux/slices/sessionSlice'
import { useFonts } from 'expo-font'
import { uuid } from '../utils/generateUuid'

export function useAppLoader(): {
  onLayoutRootView: () => Promise<void>
  appIsReady: boolean
} {
  const [loadedResources, setLoadedResources] = useState(false)
  const dispatch = useDispatch()

  // Pre-load fonts
  const [fontsLoaded] = useFonts({
    'OpenSans-Regular': require('../fonts/OpenSans-Regular.ttf'),
    'OpenSans-Medium': require('../fonts/OpenSans-Medium.ttf'),
    'OpenSans-SemiBold': require('../fonts/OpenSans-SemiBold.ttf'),
    'OpenSans-Bold': require('../fonts/OpenSans-Bold.ttf'),
    'Outfit-Light': require('../fonts/Outfit-Light.ttf'),
    'Outfit-Regular': require('../fonts/Outfit-Regular.ttf'),
    'Outfit-Medium': require('../fonts/Outfit-Medium.ttf'),
    'Outfit-SemiBold': require('../fonts/Outfit-SemiBold.ttf'),
  })

  useEffect(() => {
    // Load resources and do other stuff before the app is shown here...
    async function prepare() {
      try {
        const state = await getStateFromLocalStorage()

        if (state.active) {
          dispatch(startSession({ ...state }))
        }
      } catch (e) {
        console.warn(e)
      } finally {
        setLoadedResources(true)
      }
    }

    prepare()
  }, [])

  const onLayoutRootView = useCallback(async () => {
    if (loadedResources && fontsLoaded) {
      // This tells the splash screen to hide immediately! If we call this after
      // `setAppIsReady`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.
      await SplashScreen.hideAsync()
    }
  }, [loadedResources, fontsLoaded])

  return { onLayoutRootView, appIsReady: loadedResources && fontsLoaded }
}
