import React from 'react'
import { View, TouchableOpacity, StyleSheet } from 'react-native'
import { theme } from '../../../theme/theme'
import Input from '../../../components/Input'
import { Default } from '../../../components/Text'
import Ionicons from '@expo/vector-icons/Ionicons'
import { suffixHelper } from '../../../utils/suffixHelper'

type Props = {
  option: string
  active: boolean
  onPress: () => void
  additional?: boolean
  onPressAdditional?: () => void
  squareMarker?: boolean
  rank?: number
}

export default function ChoiceCard({
  option,
  active,
  onPress,
  additional,
  onPressAdditional,
  squareMarker,
  rank,
}: Props) {
  const { colors } = theme

  const suffix = suffixHelper(rank)
  const rankColor = rank
    ? `rgba(38, 53, 69, ${(10 - (rank - 1) * 2) / 10})`
    : undefined

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={{
        padding: 16,
        backgroundColor:
          active && rank && rankColor
            ? rankColor
            : active
            ? colors.accent
            : colors['grey-button'],
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 10,
        marginVertical: 3,
      }}
      onPress={onPress}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          flex: 1,
        }}
      >
        <View
          style={{
            height: 22,
            width: 22,
            borderRadius: squareMarker ? 4 : 11,
            borderColor: active ? colors['accent-text'] : colors.accent,
            borderStyle: 'solid',
            borderWidth: 1,
            backgroundColor:
              active && rank && rankColor
                ? rankColor
                : active
                ? colors.accent
                : colors['grey-button'],
            marginRight: 12,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {active && (
            <View
              style={{
                height: 16,
                width: 16,
                borderRadius: squareMarker ? 2 : 8,
                backgroundColor: colors['accent-text'],
              }}
            ></View>
          )}
        </View>

        <View style={{ flex: 1 }}>
          <Default
            style={{
              textAlignVertical: 'center',
              color: active ? colors['accent-text'] : colors.accent,
            }}
          >
            {option}
          </Default>
        </View>
      </View>
      <View>
        <Default
          style={{
            height: 22,
            textAlignVertical: 'center',
            color: active ? colors['accent-text'] : colors.accent,
          }}
        >
          {rank && suffix && rank + suffix}
        </Default>
        {additional && onPressAdditional && (
          <TouchableOpacity
            activeOpacity={0.8}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              width: 50,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={e => {
              e.stopPropagation()
              onPressAdditional()
            }}
          >
            <Ionicons
              name="close"
              size={16}
              color={active ? colors['accent-text'] : colors.accent}
            />
          </TouchableOpacity>
        )}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({})
