import React, { useState } from 'react'
import {
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
  ActivityIndicator,
  StatusBar,
  ScrollView,
} from 'react-native'
import { t } from 'i18n-js'
import { theme } from '../theme/theme'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation, CommonActions } from '@react-navigation/native'
import { Ionicons } from '@expo/vector-icons'
import { Header, Strong, StrongHeader } from './Text'
import PrimaryButton from './PrimaryButton'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import AllergicCheck from '../screens/Survey/components/AllergicCheck'
import LanguagePicker from './LanguagePicker'

interface PageProps {
  title?: string
  children?: JSX.Element | JSX.Element[]
  rightRender?: JSX.Element | JSX.Element[]
  screens?: number
  screensText?: string
  currentScreen?: number
  style?: StyleProp<ViewStyle>
  backButtonDisabled?: boolean
  overwriteBackButton?: () => void
  loading?: boolean
  bg?: string
  bgImage?: JSX.Element
  renderBottom?: JSX.Element | JSX.Element[]
  languagePickerAtHeader?: boolean
}

const Page: React.FC<PageProps> = ({
  title,
  children,
  rightRender,
  screens,
  screensText,
  currentScreen,
  style,
  backButtonDisabled,
  overwriteBackButton,
  loading,
  bg,
  bgImage,
  renderBottom,
  languagePickerAtHeader,
}) => {
  const navigation = useNavigation()
  const { colors } = theme

  const bgColor = bg ? bg : colors.bg

  return (
    <View
      style={{
        flex: 1,
        alignItems: 'center',
        width: '100%',
        backgroundColor: bgColor,
      }}
    >
      <View style={[styles.page, { backgroundColor: bgColor }]}>
        {bgImage && (
          <View
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            {bgImage}
          </View>
        )}
        <ScrollView
          style={{
            marginHorizontal: -theme.pagePadding,
            paddingHorizontal: theme.pagePadding,
          }}
          contentContainerStyle={{ flexGrow: 1 }}
          keyboardShouldPersistTaps="handled"
        >
          <StatusBar barStyle={'dark-content'} backgroundColor={bgColor} />
          <View style={styles.customSafeAreaViewPadding}></View>
          {(!backButtonDisabled ||
            (screens && currentScreen && screensText) ||
            rightRender) && (
            <>
              <View style={styles.spaceBetween}>
                {!backButtonDisabled ? (
                  <TouchableOpacity
                    activeOpacity={0.8}
                    style={{ flex: 1 }}
                    onPress={() => {
                      if (overwriteBackButton) return overwriteBackButton()
                      if (navigation.canGoBack()) {
                        navigation.goBack()
                      } else {
                        //@ts-ignore
                        navigation.navigate('HomeScreen')
                      }
                    }}
                  >
                    <View
                      style={{
                        width: 42,
                        height: 42,
                        borderRadius: 100,
                        backgroundColor: colors['grey-button'],
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Ionicons
                        name="chevron-back"
                        size={20}
                        color={colors.accent}
                        style={{ zIndex: 10 }}
                      />
                    </View>
                  </TouchableOpacity>
                ) : (
                  <View style={{ flex: 1 }}></View>
                )}
                {Boolean(screensText) && (
                  <View
                    style={{
                      flex: 3,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Strong style={{ fontSize: 13 }}>
                      {screensText}
                      {Boolean(screens) && Boolean(currentScreen)
                        ? ` ${currentScreen} ${t('general.of')} ${screens}`
                        : ''}
                    </Strong>
                  </View>
                )}

                <View
                  style={{
                    flex: Boolean(screensText) ? 1 : 3,
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    zIndex: 5,
                  }}
                >
                  {rightRender && <>{rightRender}</>}
                </View>
              </View>
              {Boolean(screens) &&
                Boolean(currentScreen) &&
                currentScreen &&
                Boolean(screensText) && (
                  <View
                    style={{ flexDirection: 'row', flexWrap: 'nowrap', gap: 2 }}
                  >
                    {new Array(screens).fill(0).map((value, index) => {
                      return (
                        <View
                          key={index}
                          style={{
                            flex: 1,
                            marginTop: 12,
                            backgroundColor:
                              index + 1 <= currentScreen
                                ? colors.accent
                                : colors['grey-button'],
                            height: 5,
                            borderRadius: 3,
                          }}
                        ></View>
                      )
                    })}
                  </View>
                )}
            </>
          )}

          {title && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                zIndex: 5,
              }}
            >
              <StrongHeader style={styles.header}>{title} </StrongHeader>
              {languagePickerAtHeader && <LanguagePicker />}
            </View>
          )}
          <View style={[{ flex: 1 }]}>
            {loading ? (
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ActivityIndicator size="large" color={colors.accent} />
              </View>
            ) : (
              <View style={{ flex: 1 }}>{children}</View>
            )}
          </View>

          {renderBottom && (
            <View
              style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                right: 0,
                padding: theme.pagePadding,
                flexDirection: 'column',
              }}
            >
              {renderBottom}
            </View>
          )}
        </ScrollView>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    flex: 1,
    paddingHorizontal: theme.pagePadding,
    backgroundColor: 'white',
    paddingTop: 20,
    paddingBottom: 40,
    position: 'relative',
    width: '100%',
    maxWidth: 650,
  },
  header: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  spaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 42,
    paddingBottom: 20,
    zIndex: 999,
  },
  customSafeAreaViewPadding: {
    paddingTop: 40,
  },
})

export default Page
