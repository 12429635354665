import React, { useState, Dispatch, SetStateAction, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import Input from '../../../components/Input'
import CodeInput from '../../../components/CodeInput'
import QuestionHeader from '../../Survey/components/QuestionHeader'
import { theme } from '../../../theme/theme'
import PasswordInput from './PasswordInput'
import { t } from 'i18n-js'

type ResetPasswordFlowProps = {
  email: string
  setEmail: Dispatch<SetStateAction<string>>
  emailCode: string
  setEmailCode: Dispatch<SetStateAction<string>>
  password: string
  setPassword: Dispatch<SetStateAction<string>>
  confirmPassword: string
  setConfirmPassword: Dispatch<SetStateAction<string>>
  loading?: boolean
  emailCodeError: boolean
  screen: number
}

export default function ResetPasswordFlow({
  email,
  setEmail,
  emailCode,
  setEmailCode,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  loading,
  emailCodeError,
  screen,
}: ResetPasswordFlowProps) {
  const { colors } = theme
  const [birthdateError, setBirthdateError] = useState(false)
  const [birthdateCode, setCode] = useState('')
  const [emailCodeCode, setEmailCodeCode] = useState('')
  const codeLength = 4

  useEffect(() => {
    if (emailCodeCode.length === codeLength) {
      setEmailCode(emailCodeCode)
    } else {
      setEmailCode('')
    }
  }, [emailCodeCode])

  return (
    <>
      {screen === 1 && (
        <>
          <QuestionHeader
            question={t('forgot-password.reset-password')}
            strong
          />
          <Input
            onChangeText={text => setEmail(text)}
            value={email}
            placeholder={t('sign-up.email')}
            autoCapitalize={'none'}
            keyboard="email-address"
          />
        </>
      )}
      {screen === 2 && (
        <>
          <QuestionHeader
            question={t('forgot-password.check-your-inbox')}
            descriptionBottom={t('success.email-sent', { email })}
            strong
          />

          <Input
            onChangeText={setEmailCodeCode}
            value={emailCodeCode}
            placeholder={t('forgot-password.code')}
            autoCapitalize={'none'}
            keyboard="default"
            error={emailCodeError ? t('validation.invalid-code') : undefined}
          />
          {/* <CodeInput
            codeLength={codeLength}
            setCode={setEmailCodeCode}
            code={emailCodeCode}
            error={emailCodeError}
            errorMessage={t('validation.invalid-code')}
            loading={loading}
          /> */}
        </>
      )}
      {screen === 3 && (
        <>
          <QuestionHeader question={t('forgot-password.new-password')} strong />
          <PasswordInput
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
          />
        </>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 40,
    padding: 12,
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
})
