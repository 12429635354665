import React, { useContext, useLayoutEffect } from 'react'
import { HomeNavigatorParamList } from '../types/NavigationTypes'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import HomeScreen from '../screens/Home/HomeScreen'
import SurveyNavigator from './SurveyNavigator'
import {
  getFocusedRouteNameFromRoute,
  useNavigation,
  useRoute,
} from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { HideTabBarContext } from '../contexts/HideTabBarContext'
import getCurrentProfileId from '../utils/getCurrentProfileId'
import { RootState } from '../redux/store'

interface NavigationProps {}

const Stack = createNativeStackNavigator<HomeNavigatorParamList>()

const HomeNavigator: React.FC<NavigationProps> = () => {
  const navigation = useNavigation()
  const route = useRoute()

  const session = useSelector((state: RootState) => state.session)
  const profileId = getCurrentProfileId(session)

  const { hideTabBar, setHideTabBar } = useContext(HideTabBarContext)

  useLayoutEffect(() => {
    const routeName = getFocusedRouteNameFromRoute(route)

    if (routeName === 'HomeScreen') {
      setHideTabBar(false)
    }

    return () => {
      setHideTabBar(false)
    }
  }, [navigation, route, profileId])

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={{ title: 'Events / SensiMate' }}
      />
      <Stack.Screen name="SurveyNavigator" component={SurveyNavigator} />
    </Stack.Navigator>
  )
}

export default HomeNavigator
