import React, { useContext, useEffect, useState } from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { RouteProp } from '@react-navigation/native'
import { SurveyNavigatorParamList } from '../../types/NavigationTypes'
import PrimaryButton from '../../components/PrimaryButton'
import Page from '../../components/Page'
import SurveyQuestion from './components/SurveyQuestion'
import {
  useCreateAnswerMutation,
  useGetAnswerByIdQuery,
  useGetSurveyQuery,
} from '../../redux/services/coreApi'
import { validateAnswer } from '../../utils/validation'
import Ionicons from '@expo/vector-icons/Ionicons'
import { theme } from '../../theme/theme'
import alert from '../../utils/alertPolyfill'
import { QuestionDto } from '../../src/types/coreApi-types'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { useToast } from 'react-native-toast-notifications'
import { errorToastOptions } from '../../utils/toastOptions'
import getCurrentProfileId from '../../utils/getCurrentProfileId'
import { t } from 'i18n-js'
import { LocaleContext } from '../../contexts/LocaleContext'
import { startSession } from '../../redux/slices/sessionSlice'

type SurveyReadyScreenNavigationProps = NativeStackNavigationProp<
  SurveyNavigatorParamList,
  'SurveyReadyScreen'
>
type SurveyReadyScreenRouteProps = RouteProp<
  SurveyNavigatorParamList,
  'SurveyReadyScreen'
>

type Props = {
  navigation: SurveyReadyScreenNavigationProps
  route: SurveyReadyScreenRouteProps
}

export default function SurveyReadyScreen({ navigation, route }: Props) {
  const { colors } = theme
  const toast = useToast()
  const [firstTime, setFirstTime] = useState(true)

  const { surveyId, temporary } = route.params
  const { locale, setLocale } = useContext(LocaleContext)
  const { data: survey, error } = useGetSurveyQuery(
    { surveyId, locale },
    {
      skip: firstTime === false,
    }
  )
  const [questionStep, setQuestionStep] = useState(0)
  const questions = survey?.questions || []
  const question = questions[questionStep] as QuestionDto | undefined

  const { isFetching: isGettingAnswer, currentData: answer } =
    useGetAnswerByIdQuery(question?.id ?? skipToken, {
      skip: question === undefined,
    })
  const [createAnswer, { isLoading: isCreatingAnswer }] =
    useCreateAnswerMutation()

  const [answersState, setAnswersState] = useState<string[]>([])
  const [columnAnswersState, setColumnAnswersState] = useState<string[]>([])

  const [previousAnswersState, setPreviousAnswersState] = useState<string[]>([])
  const [previousColumnAnswersState, setPreviousColumnAnswersState] = useState<
    string[]
  >([])

  const [isLoading, setIsLoading] = useState(false)

  const session = useSelector((state: RootState) => state.session)

  useEffect(() => {
    setAnswersState(answer?.answers ?? [])
    setColumnAnswersState(answer?.columnAnswers ?? [])

    setPreviousAnswersState(answer?.answers ?? [])
    setPreviousColumnAnswersState(answer?.columnAnswers ?? [])
  }, [question, answer])

  useEffect(() => {
    if (survey && firstTime) {
      const lastQuestionWithAnswer = survey.questions.reduce(
        (acc, curr, index) => {
          if (
            curr.answers.length > 0 &&
            curr.answers[0].profileId === getCurrentProfileId(session)
          )
            return index
          return acc
        },
        -1
      )

      if (
        lastQuestionWithAnswer >= 0 &&
        lastQuestionWithAnswer < questions.length
      ) {
        if (lastQuestionWithAnswer === questions.length - 1) {
          // Have answered all questions... go to start
          setQuestionStep(0)
        } else {
          setQuestionStep(lastQuestionWithAnswer + 1)
        }
      }
      setFirstTime(false)
    }
  }, [survey])

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 100)
  }, [questionStep])

  useEffect(() => {
    if (error && 'data' in error && error.status === 404) {
      navigation.navigate('HomeScreen')
    }
  }, [error])

  const isSignedIn = session.guestProfile || session.token
  const dispatch = useDispatch()

  return (
    <Page
      loading={isGettingAnswer || isCreatingAnswer || isLoading || !question}
      screens={questions.length}
      currentScreen={questionStep + 1}
      screensText={t('events.question')}
      backButtonDisabled={questionStep === 0}
      rightRender={
        <TouchableOpacity
          activeOpacity={0.8}
          onPress={() => {
            alert(
              t('events.survey-in-progress.title'),
              t('events.survey-in-progress.description'),
              [
                {
                  text: t('general.cancel'),
                  onPress: () => {},
                  style: 'cancel',
                },
                {
                  text: t('general.exit'),
                  onPress: () => {
                    // If just "navigate" is used here, it can bug out the tab bar.
                    if (isSignedIn) {
                      navigation.reset({
                        routes: [
                          {
                            name: 'HomeScreen',
                          },
                        ],
                      })
                    } else {
                      // Assign the Guest Profile so if a QR Code flow Temp Guest prematurely ends the Survey, they will keep progress.
                      dispatch(
                        startSession({
                          guestProfile: session.temporaryGuestProfile,
                        })
                      )
                      navigation.reset({
                        routes: [
                          {
                            name: 'SurveyOverviewScreen',
                            params: { surveyId: surveyId },
                          },
                        ],
                      })
                    }
                  },
                  style: 'destructive',
                },
              ]
            )
          }}
        >
          <View
            style={{
              width: 42,
              height: 42,
              borderRadius: 100,
              backgroundColor: colors['grey-button'],
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Ionicons
              name="close"
              size={18}
              color={colors.accent}
              style={{ zIndex: 10 }}
            />
          </View>
        </TouchableOpacity>
      }
      overwriteBackButton={() => {
        setIsLoading(true)
        setQuestionStep(questionStep => questionStep - 1)
      }}
    >
      <View style={styles.container}>
        {question && (
          <>
            <View style={{ flex: 1 }}>
              <SurveyQuestion
                question={question}
                answersState={answersState}
                setAnswersState={setAnswersState}
                columnAnswersState={columnAnswersState}
                setColumnAnswersState={setColumnAnswersState}
              />
            </View>
            <View style={{ flexDirection: 'row', flexWrap: 'nowrap', gap: 6 }}>
              {question.optional && (
                <PrimaryButton
                  secondary
                  disabled={isGettingAnswer || isCreatingAnswer}
                  loading={isCreatingAnswer}
                  title={t('events.skip')}
                  style={{ flex: 2 }}
                  onPress={async () => {
                    setIsLoading(true)
                    if (
                      previousColumnAnswersState.length !== 0 ||
                      previousAnswersState.length !== 0
                    ) {
                      try {
                        await createAnswer({
                          questionId: question.id,
                          answers: [],
                          columnAnswers: [],
                        }).unwrap()
                      } catch (error) {
                        toast.show(
                          t('validation.something-went-wrong'),
                          errorToastOptions
                        )
                        setIsLoading(false)
                        return
                      }
                    }

                    if (questionStep >= questions.length - 1) {
                      if (survey) {
                        navigation.navigate('SurveyFinishedScreen', {
                          eventId: survey.eventId,
                          surveyId: survey.id,
                          temporary: temporary,
                        })
                      }
                    } else {
                      setQuestionStep(questionStep => questionStep + 1)
                    }
                  }}
                />
              )}
              <PrimaryButton
                title={
                  questionStep >= questions.length - 1
                    ? t('events.done')
                    : t('events.next')
                }
                style={{ flex: 4 }}
                disabled={
                  isGettingAnswer ||
                  isCreatingAnswer ||
                  !validateAnswer(question, answersState, columnAnswersState)
                }
                loading={isCreatingAnswer}
                onPress={async () => {
                  setIsLoading(true)
                  if (
                    JSON.stringify(previousAnswersState) !==
                      JSON.stringify(answersState) ||
                    JSON.stringify(previousColumnAnswersState) !==
                      JSON.stringify(columnAnswersState)
                  ) {
                    try {
                      await createAnswer({
                        questionId: question.id,
                        answers: answersState,
                        columnAnswers: columnAnswersState,
                      }).unwrap()
                    } catch (error) {
                      toast.show(
                        t('validation.something-went-wrong'),
                        errorToastOptions
                      )
                      setIsLoading(false)
                      return
                    }
                  }

                  if (questionStep >= questions.length - 1) {
                    if (survey) {
                      navigation.navigate('SurveyFinishedScreen', {
                        eventId: survey.eventId,
                        surveyId: survey.id,
                        temporary: temporary,
                      })
                    }
                  } else {
                    setQuestionStep(questionStep => questionStep + 1)
                  }
                }}
              />
            </View>
          </>
        )}
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 40,
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
})
