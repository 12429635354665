import { Linking, Platform } from 'react-native'

export const redirectToPage = (url: string, deepLinkUrl: string) => {
  if (Platform.OS === 'android' || Platform.OS === 'ios') {
    Linking.canOpenURL(deepLinkUrl).then(supported => {
      if (supported) {
        Linking.openURL(deepLinkUrl)
      } else {
        Linking.openURL(url)
      }
    })
  } else if (navigator.userAgent.match(/(android|iphone|ipad)/i)) {
    window.open(url, '_blank') // Opens tab on browser, needs deeplink handling on browser on phone
  } else {
    window.open(url, '_blank') // Redirect to web URL for other platforms
  }
}
