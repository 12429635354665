import React, { Dispatch, SetStateAction, useState } from 'react'
import { View, StyleSheet } from 'react-native'
import { theme } from '../../../theme/theme'
import {
  Description,
  Header,
  StrongHeader,
  Default,
} from '../../../components/Text'
import { t } from 'i18n-js'

type Props = {
  question: string
  description?: string
  strong?: boolean
  descriptionBottom?: string
  minimum?: number | null
  maximum?: number | null
  center?: boolean
  optional?: boolean
}

export default function QuestionHeader({
  question,
  description,
  strong,
  descriptionBottom,
  minimum,
  maximum,
  center,
  optional,
}: Props) {
  const { colors } = theme

  return (
    <View style={styles.container}>
      {description && (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Description style={{ color: theme.colors.secondary }}>
            {description}
          </Description>

          <Description
            style={{
              color: theme.colors.description,
              fontSize: theme.textSizes.sm,
            }}
          >
            {Boolean(minimum) && `${t('events.min')}: ` + minimum}
            {Boolean(minimum) && Boolean(maximum) && ', '}
            {Boolean(maximum) && `${t('events.max')}: ` + maximum}
          </Description>
        </View>
      )}
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {!strong && (
          <Header
            style={[styles.header, { textAlign: center ? 'center' : 'auto' }]}
          >
            {question}
          </Header>
        )}
        {strong && (
          <StrongHeader
            style={[styles.header, { textAlign: center ? 'center' : 'auto' }]}
          >
            {question}
          </StrongHeader>
        )}
        {optional && (
          <Default
            style={{
              marginTop: 4,
              marginLeft: 6,
              fontSize: 14,
              color: '#A8AEB5',
            }}
          >
            {`(${t('events.optional')})`}
          </Default>
        )}
      </View>

      {descriptionBottom && (
        <Description
          style={[
            styles.descriptionBottom,
            { textAlign: center ? 'center' : 'auto' },
          ]}
        >
          {descriptionBottom}
        </Description>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    marginBottom: 16,
  },
  header: {
    marginTop: 4,
    flexWrap: 'wrap',
  },
  descriptionBottom: {
    fontSize: theme.textSizes.sm,
    marginTop: 4,
  },
})
