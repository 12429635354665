import React, { useState } from 'react'
import { TouchableOpacity, Platform, View } from 'react-native'
import { theme } from '../theme/theme'
import InnerButton from './InnerButton'
import { useLinkProps } from '@react-navigation/native'
import { To } from '@react-navigation/native/lib/typescript/src/useLinkTo'
import { UnionNavigatorParamList } from '../types/NavigationTypes'

interface LinkButtonProps {
  title: string
  secondary?: boolean
  tertiary?: boolean
  disabled?: boolean
  loading?: boolean
  style?: React.CSSProperties
  renderRight?: JSX.Element
  renderLeft?: JSX.Element
  fontSize?: number
  rounded?: boolean
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  to: To<UnionNavigatorParamList>
  action?: any
  transparent?: boolean
}

const LinkButton: React.FC<LinkButtonProps> = ({
  title,
  secondary,
  tertiary,
  disabled,
  loading,
  style,
  renderRight,
  renderLeft,
  fontSize,
  rounded,
  justifyContent,
  to,
  action,
  transparent,
}) => {
  const { colors } = theme
  const { onPress, ...props } = useLinkProps({ to, action })

  const [isHovered, setIsHovered] = useState(false)

  if (Platform.OS === 'web') {
    // It's important to use a `View` or `Text` on web instead of `TouchableX`
    // Otherwise React Native for Web omits the `onClick` prop that's passed
    // You'll also need to pass `onPress` as `onClick` to the `View`
    // You can add hover effects using `onMouseEnter` and `onMouseLeave`
    return (
      <View
        // @ts-ignore
        onClick={onPress}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={[
          {
            backgroundColor: transparent
              ? 'transparent'
              : secondary
              ? colors['grey-button']
              : tertiary
              ? colors.bg
              : colors.accent,
            height: 50,
            paddingHorizontal: 16,
            borderRadius: rounded ? 25 : 8,
            opacity: disabled ? 0.3 : 1,
            justifyContent: 'center',
          },
          style as any,
        ]}
        {...props}
      >
        <InnerButton
          loading={loading}
          justifyContent={justifyContent}
          renderLeft={renderLeft}
          secondary={secondary}
          tertiary={tertiary}
          fontSize={fontSize}
          title={title}
          renderRight={renderRight}
        />
      </View>
    )
  }

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      disabled={disabled}
      style={[
        {
          backgroundColor: transparent
            ? 'transparent'
            : secondary
            ? colors['grey-button']
            : tertiary
            ? colors.bg
            : colors.accent,
          height: 50,
          paddingHorizontal: 16,
          borderRadius: rounded ? 25 : 8,
          opacity: disabled ? 0.3 : 1,
          justifyContent: 'center',
        },
        style as any,
      ]}
      onPress={onPress}
    >
      <InnerButton
        loading={loading}
        justifyContent={justifyContent}
        renderLeft={renderLeft}
        secondary={secondary}
        tertiary={tertiary}
        fontSize={fontSize}
        title={title}
        renderRight={renderRight}
      />
    </TouchableOpacity>
  )
}

export default LinkButton
