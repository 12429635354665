import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { ProfileNavigatorParamList } from '../../types/NavigationTypes'
import { RouteProp } from '@react-navigation/native'
import PrimaryButton from '../../components/PrimaryButton'
import Page from '../../components/Page'
import { theme } from '../../theme/theme'
import { useCreateFeedbackMutation } from '../../redux/services/coreApi'
import Input from '../../components/Input'
import { useToast } from 'react-native-toast-notifications'
import { successToastOptions } from '../../utils/toastOptions'
import { t } from 'i18n-js'

type FeedbackScreenNavigationProps = NativeStackNavigationProp<
  ProfileNavigatorParamList,
  'FeedbackScreen'
>
type FeedbackScreenRouteProps = RouteProp<
  ProfileNavigatorParamList,
  'FeedbackScreen'
>

type Props = {
  navigation: FeedbackScreenNavigationProps
  route: FeedbackScreenRouteProps
}

export default function FeedbackScreen({ navigation, route }: Props) {
  const { colors } = theme

  const [createFeedback, { isLoading: isCreatingFeedback }] =
    useCreateFeedbackMutation()
  const [description, setDescription] = useState<string>('')
  const [rating, setRating] = useState<number>()

  const toast = useToast()

  const emojis = [
    {
      emoji: '😢',
      rating: 1,
    },
    {
      emoji: '😕',
      rating: 2,
    },
    {
      emoji: '😐',
      rating: 3,
    },
    {
      emoji: '😊',
      rating: 4,
    },
    {
      emoji: '😍',
      rating: 5,
    },
  ]

  return (
    <Page title={t('feedback.title')}>
      <View style={styles.container}>
        <View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: 6,
              marginBottom: 6,
              marginTop: 10,
            }}
          >
            {emojis.reverse().map(item => (
              <PrimaryButton
                key={item.emoji}
                style={{ flex: 1 }}
                secondary={item.rating !== rating}
                title={item.emoji}
                onPress={() => setRating(item.rating)}
                fontSize={theme.textSizes.xxl}
              />
            ))}
          </View>
          <Input
            hideBorder
            placeholder={t('feedback.elaborate-answer')}
            numberOfLines={8}
            maxLength={255}
            onChangeText={text => setDescription(text)}
            value={description}
            style={{
              backgroundColor: colors['grey-button'],
              borderRadius: 8,
            }}
          />
        </View>
        <PrimaryButton
          title={t('feedback.send-feedback')}
          disabled={!rating || isCreatingFeedback}
          loading={isCreatingFeedback}
          onPress={() => {
            if (!rating) return
            createFeedback({ rating, description })
              .unwrap()
              .then(() => {
                setRating(undefined)
                setDescription('')
                toast.show(t('success.feedback-given'), successToastOptions)
                navigation.pop()
              })
          }}
        />
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
})
