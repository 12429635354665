import React, { useContext } from 'react'
import { View, RefreshControl, ScrollView } from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { HomeNavigatorParamList } from '../../types/NavigationTypes'
import { RouteProp } from '@react-navigation/native'
import { useGetSurveysQuery } from '../../redux/services/coreApi'
import Page from '../../components/Page'
import SurveyCard from './components/SurveyCard'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { theme } from '../../theme/theme'
import { LocaleContext } from '../../contexts/LocaleContext'
import { t } from 'i18n-js'

export type HomeScreenNavigationProps = NativeStackNavigationProp<
  HomeNavigatorParamList,
  'HomeScreen'
>
type HomeScreenRouteProps = RouteProp<HomeNavigatorParamList, 'HomeScreen'>

type Props = {
  navigation: HomeScreenNavigationProps
  route: HomeScreenRouteProps
}

export default function HomeScreen({ navigation, route }: Props) {
  const { locale, setLocale } = useContext(LocaleContext)
  const { data: surveys, isFetching, refetch } = useGetSurveysQuery(locale)

  const session = useSelector((state: RootState) => state.session)

  return (
    <Page
      backButtonDisabled
      title={t('events.title')}
      loading={isFetching}
      languagePickerAtHeader
    >
      <View style={{ flex: 1 }}>
        <ScrollView
          style={{
            marginHorizontal: -theme.pagePadding,
            paddingHorizontal: theme.pagePadding,
          }}
          refreshControl={
            <RefreshControl refreshing={isFetching} onRefresh={refetch} />
          }
        >
          {surveys?.map((survey, i) => (
            <SurveyCard
              key={survey.id}
              survey={survey}
              onPress={() =>
                navigation.navigate('SurveyNavigator', {
                  screen: 'SurveyOverviewScreen',
                  params: {
                    surveyId: survey.id,
                  },
                })
              }
            />
          ))}
        </ScrollView>
      </View>
    </Page>
  )
}
