import React, { useEffect, useState } from 'react'
import {
  View,
  StyleSheet,
  Button,
  Alert,
  TouchableOpacity,
  KeyboardAvoidingView,
  Keyboard,
  Platform,
} from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import {
  AuthNavigationParamList,
  HomeNavigatorParamList,
  SurveyNavigatorParamList,
} from '../../types/NavigationTypes'
import type { RouteProp } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { startSession } from '../../redux/slices/sessionSlice'
import Input from '../../components/Input'
import Page from '../../components/Page'
import PrimaryButton from '../../components/PrimaryButton'
import {
  useAssignCodeMutation,
  useCreateProfileMutation,
  useValidateCodeMutation,
} from '../../redux/services/coreApi'
import 'react-native-get-random-values'
import GuestSignupFlow from './components/GuestSignupFlow'
import { validateGuestSignup } from '../../utils/validation'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faHouse } from '@fortawesome/free-solid-svg-icons/faHouse'
import { theme } from '../../theme/theme'
import * as Text from '../../components/Text'
import { RootState } from '../../redux/store'
import { uuid } from '../../utils/generateUuid'
import sleep from '../../utils/sleep'
import KeyboardAvoidingViewWeb from '../../components/KeyboardAvoidingViewWeb'
import { useKeyboardListener } from '../../hooks/useKeyboardListener'
import { t } from 'i18n-js'

type TemporaryGuestSignupScreenNavigationProps = NativeStackNavigationProp<
  SurveyNavigatorParamList,
  'TemporaryGuestSignupScreen'
>
type TemporaryGuestSignupScreenRouteProps = RouteProp<
  SurveyNavigatorParamList,
  'TemporaryGuestSignupScreen'
>

type Props = {
  navigation: TemporaryGuestSignupScreenNavigationProps
  route: TemporaryGuestSignupScreenRouteProps
}

export default function TemporaryGuestSignupScreen({
  navigation,
  route,
}: Props) {
  const { surveyId, eventId, temporary, eventCode } = route.params
  const { colors } = theme
  const dispatch = useDispatch()
  const [birthdate, setBirthdate] = useState<string>()
  const [gender, setGender] = useState<
    'Female' | 'Male' | 'NonBinary' | undefined
  >()
  const [zipCode, setZipCode] = useState('')
  const [screen, setScreen] = useState(1)
  const screens = 3
  const [validCode, setValidCode] = useState<boolean | undefined>(undefined)

  const [validateCode] = useValidateCodeMutation()
  const session = useSelector((state: RootState) => state.session)

  const [createProfile, { isLoading: isCreatingProfile }] =
    useCreateProfileMutation()

  const [assignCode, { isLoading: isAssigningCode }] = useAssignCodeMutation()

  const { inputProps, keyboardOpen } = useKeyboardListener()

  useEffect(() => {
    const checkCode = async () => {
      if (eventCode && eventId) {
        try {
          const validateCodeRes = await validateCode({
            code: eventCode,
            eventId: eventId,
          }).unwrap()
          setValidCode(true)
        } catch (e) {
          setValidCode(false)
        }
      } else {
        // Code is not required
        setValidCode(true)
      }
    }
    checkCode()
  }, [eventCode, eventId])

  async function signInAsTemporaryGuest(skipZipCode?: boolean) {
    if (!birthdate) return

    const newUuid = uuid()
    // Set session state before createProfile, as createProfile will invalidate
    // cache of Surveys with the new UUID in Authorization (instead of whatever
    // was the real User)
    dispatch(
      startSession({
        temporaryGuestProfile: {
          id: newUuid,
          birthdate: new Date(birthdate).toISOString(),
          gender: gender || null,
          zipCode: skipZipCode ? '' : zipCode,
        },
      })
    )
    await createProfile({
      birthdate: new Date(birthdate).toISOString(),
      id: newUuid,
      gender,
      zipCode: skipZipCode ? '' : zipCode,
    }).unwrap()

    // Caching on "pick up where you left off" can be fixed by waiting for the event loop here
    await sleep(500)

    if (eventCode) {
      await assignCode({
        code: eventCode,
        eventId: eventId,
      }).unwrap()
      navigation.navigate('SurveyReadyScreen', {
        surveyId,
        temporary: temporary,
      })
    } else {
      navigation.navigate('SurveyReadyScreen', {
        surveyId,
        temporary: temporary,
      })
    }
  }

  if (validCode === false) {
    return (
      <Page backButtonDisabled>
        <View
          style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        >
          <Text.Header style={{ marginBottom: 4 }}>
            {t('validation.invalid-code-title')}
          </Text.Header>
          <Text.Description style={{ marginBottom: 20 }}>
            {t('validation.invalid-code-or-already-in-use')}
          </Text.Description>

          <PrimaryButton
            secondary
            title={t('general.go-back')}
            renderLeft={
              <FontAwesomeIcon
                icon={faHouse}
                size={14}
                style={{ color: colors.accent }}
              />
            }
            onPress={() => {
              navigation.navigate('HomeScreen')
            }}
          />
        </View>
      </Page>
    )
  }

  return (
    <Page
      screensText={
        session.guestProfile ? t('events.let-my-friend-take-survey') : ''
      }
    >
      <View style={styles.container}>
        <View>
          <GuestSignupFlow
            birthdate={birthdate}
            setBirthDate={setBirthdate}
            gender={gender}
            setGender={setGender}
            zipCode={zipCode}
            setZipCode={setZipCode}
            keyboardListenerInputProps={inputProps}
          />
        </View>

        <KeyboardAvoidingViewWeb keyboardOpen={keyboardOpen}>
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'nowrap',
              gap: 6,
            }}
          >
            <PrimaryButton
              title={t('general.continue')}
              disabled={
                isCreatingProfile ||
                !birthdate ||
                !validateGuestSignup(birthdate, gender, zipCode)
              }
              loading={isCreatingProfile}
              style={{ flex: 4 }}
              onPress={() => {
                signInAsTemporaryGuest()
              }}
            />
          </View>
        </KeyboardAvoidingViewWeb>
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 40,
    justifyContent: 'space-between',
    flex: 1,
  },
})
