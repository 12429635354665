export function monthToUpperCase(str: string) {
  const isLetterLong = str.split(' ')[0].length > 1
  const splitIndex = isLetterLong ? 3 : 2

  return (
    str.slice(0, splitIndex) +
    str.charAt(splitIndex).toUpperCase() +
    str.slice(isLetterLong ? 4 : 3)
  )
}
