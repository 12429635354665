import React from 'react'
import { View, ActivityIndicator, TextStyle } from 'react-native'
import { Default, Strong } from './Text'
import { theme } from '../theme/theme'

interface InnerButtonProps {
  title: string
  secondary?: boolean
  tertiary?: boolean
  loading?: boolean
  renderRight?: JSX.Element
  renderLeft?: JSX.Element
  fontSize?: number
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  textStyle?: TextStyle
}

const InnerButton: React.FC<InnerButtonProps> = ({
  loading,
  justifyContent,
  renderLeft,
  secondary,
  tertiary,
  fontSize,
  title,
  renderRight,
  textStyle,
}) => {
  const { colors } = theme

  return (
    <>
      {loading ? (
        <ActivityIndicator size="large" color={colors.accent} />
      ) : (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: justifyContent ? justifyContent : 'center',
            alignItems: 'center',
            gap: 8,
          }}
        >
          {renderLeft && <View>{renderLeft}</View>}
          <Strong
            style={[
              {
                color: secondary
                  ? colors.accent
                  : tertiary
                  ? colors.accent
                  : colors['accent-text'],
                fontSize: fontSize,
                minWidth: 23,
                textAlignVertical: 'center',
              },
              textStyle,
            ]}
            numberOfLines={1}
          >
            {title}
          </Strong>
          {renderRight && <View>{renderRight}</View>}
        </View>
      )}
    </>
  )
}

export default InnerButton
