import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import AsyncStorage from '@react-native-async-storage/async-storage'
import 'react-native-get-random-values'
import { v4 as uuidv4 } from 'uuid'
import { ProfileDto } from '../../src/types/coreApi-types'
import { uuid } from '../../utils/generateUuid'

export interface SessionState {
  isLoading: boolean
  active: boolean
  developerInfoUnlocked: boolean
  guestProfile?: ProfileDto | null
  temporaryGuestProfile?: ProfileDto | null
  token?: string | null
  refreshToken?: string | null
  onTemporaryRoute: boolean
}

export const initialState: SessionState = {
  isLoading: true,
  active: false,
  developerInfoUnlocked: false,
  guestProfile: undefined,
  temporaryGuestProfile: undefined,
  token: undefined,
  refreshToken: undefined,
  onTemporaryRoute: false,
}

export async function getDeviceIdFromLocalStorage() {
  const asyncStorageDeviceId = await AsyncStorage.getItem('@deviceId')
  if (asyncStorageDeviceId) return asyncStorageDeviceId

  const firstTimeDeviceUUID = uuid()
  await AsyncStorage.setItem('@deviceId', firstTimeDeviceUUID)
  return firstTimeDeviceUUID
}

export async function saveStateToLocalStorage(state: SessionState) {
  const newState = { ...current(state) }
  const asyncStorageSessionState = await AsyncStorage.getItem('@sessionState')

  if (asyncStorageSessionState) {
    const jsonAsyncStorageSessionState = JSON.parse(
      asyncStorageSessionState
    ) as SessionState

    const updatedSessionState = {
      ...jsonAsyncStorageSessionState,
      ...newState,
    }
    const sessionStateRaw = JSON.stringify(updatedSessionState)
    await AsyncStorage.setItem('@sessionState', sessionStateRaw)
  } else {
    const sessionStateRaw = JSON.stringify(newState)
    await AsyncStorage.setItem('@sessionState', sessionStateRaw)
  }
}

export async function getStateFromLocalStorage() {
  const asyncStorageSessionState = await AsyncStorage.getItem('@sessionState')

  if (asyncStorageSessionState) {
    const sessionState = JSON.parse(asyncStorageSessionState) as SessionState

    return sessionState
  }

  return initialState
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    startSession: (
      state: SessionState,
      action: PayloadAction<{
        guestProfile?: ProfileDto | null
        temporaryGuestProfile?: ProfileDto | null
        token?: string | null
        refreshToken?: string | null
      }>
    ) => {
      state.active = true
      state.isLoading = false

      if (action.payload.guestProfile !== undefined) {
        state.guestProfile = action.payload.guestProfile
      }
      if (action.payload.temporaryGuestProfile !== undefined) {
        state.temporaryGuestProfile = action.payload.temporaryGuestProfile
      }
      if (action.payload.token !== undefined) {
        state.token = action.payload.token
      }
      if (action.payload.refreshToken !== undefined) {
        state.refreshToken = action.payload.refreshToken
      }

      saveStateToLocalStorage(state)
    },
    endSession: (state: SessionState) => {
      state.active = false
      state.token = undefined
      state.refreshToken = undefined

      saveStateToLocalStorage(state)
    },
    setSessionLoading: (
      state: SessionState,
      action: PayloadAction<{ isLoading: boolean }>
    ) => {
      state.isLoading = action.payload.isLoading
    },
    setDeveloperInfoUnlocked: (
      state: SessionState,
      action: PayloadAction<{ developerInfoUnlocked: boolean }>
    ) => {
      state.developerInfoUnlocked = action.payload.developerInfoUnlocked
    },
    setOnTemporaryRoute: (
      state: SessionState,
      action: PayloadAction<{ onTemporaryRoute: boolean }>
    ) => {
      state.onTemporaryRoute = action.payload.onTemporaryRoute
    },
  },
})

export const {
  startSession,
  endSession,
  setSessionLoading,
  setDeveloperInfoUnlocked,
  setOnTemporaryRoute,
} = sessionSlice.actions

export default sessionSlice.reducer
