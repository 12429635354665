import { theme } from '../theme/theme'
import { ToastOptions } from 'react-native-toast-notifications/lib/typescript/toast'

const { colors } = theme

export const successToastOptions: ToastOptions = {
  type: 'success',
  placement: 'top',
  duration: 2000,
  animationType: 'slide-in',
  style: {
    backgroundColor: colors.accent,
    borderRadius: 100,
    paddingHorizontal: 20,
  },
}

export const successContrastToastOptions: ToastOptions = {
  type: 'success',
  placement: 'top',
  duration: 2000,
  animationType: 'slide-in',
  textStyle: { color: colors.accent },
  style: {
    backgroundColor: colors.bg,
    borderRadius: 100,
    paddingHorizontal: 20,
  },
}

export const errorToastOptions: ToastOptions = {
  type: 'error',
  placement: 'top',
  duration: 2000,
  animationType: 'slide-in',
  style: {
    backgroundColor: colors.warning,
    borderRadius: 100,
    paddingHorizontal: 20,
  },
}
