import React from 'react'
import {
  GestureResponderEvent,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import PrimaryButton from '../../../components/PrimaryButton'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { theme } from '../../../theme/theme'
import { Description, Default } from '../../../components/Text'

interface PrimaryButtonProps {
  title: string
  onPress?: (event: GestureResponderEvent) => void
  rightText?: string | null
  disabled?: boolean
  warning?: boolean
}

const ProfileButton: React.FC<PrimaryButtonProps> = ({
  title,
  onPress,
  rightText,
  disabled,
  warning,
}) => {
  const { colors } = theme

  return (
    <PrimaryButton
      tertiary
      textStyle={{ color: warning ? colors.warning : colors.accent }}
      title={title}
      onPress={!disabled ? onPress : undefined}
      style={{
        borderStyle: 'solid',
        borderBottomWidth: 1,
        borderColor: colors['border-subtle'],
        borderRadius: 0,
        height: 60,
      }}
      justifyContent="space-between"
      renderRight={
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {rightText && (
            <Description style={{ color: colors.description }}>
              {rightText}
            </Description>
          )}
          {onPress && !disabled && (
            <FontAwesomeIcon
              size={10}
              style={{ color: colors.border, marginLeft: 8 }}
              icon={faChevronRight}
            />
          )}
        </View>
      }
    />
  )
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: -theme.pagePadding,
    paddingHorizontal: theme.pagePadding,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

export default ProfileButton
