import React from 'react'
import { ProfileNavigatorParamList } from '../types/NavigationTypes'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import ProfileScreen from '../screens/Profile/ProfileScreen'
import GuestCompleteSignupScreen from '../screens/Auth/GuestCompleteSignupScreen'
import EditProfileScreen from '../screens/Profile/EditProfileScreen'
import AboutScreen from '../screens/Profile/AboutScreen'
import PreviousAnswersScreen from '../screens/Profile/PreviousAnswersScreen'
import SupportScreen from '../screens/Profile/SupportScreen'
import FeedbackScreen from '../screens/Profile/FeedbackScreen'
import FAQScreen from '../screens/Profile/FAQScreen'

interface NavigationProps {}

const Stack = createNativeStackNavigator<ProfileNavigatorParamList>()

const ProfileNavigator: React.FC<NavigationProps> = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="ProfileScreen"
        component={ProfileScreen}
        options={{ title: 'Profile / SensiMate' }}
      />
      <Stack.Screen
        name="AboutScreen"
        component={AboutScreen}
        options={{ title: 'About / SensiMate' }}
      />
      <Stack.Screen
        name="FeedbackScreen"
        component={FeedbackScreen}
        options={{ title: 'Feedback / SensiMate' }}
      />
      <Stack.Screen
        name="PreviousAnswersScreen"
        component={PreviousAnswersScreen}
        options={{ title: 'Prevous answers / SensiMate' }}
      />
      <Stack.Screen
        name="SupportScreen"
        component={SupportScreen}
        options={{ title: 'Support / SensiMate' }}
      />
      <Stack.Screen
        name="FAQScreen"
        component={FAQScreen}
        options={{ title: 'FAQs / SensiMate' }}
      />
      <Stack.Screen
        name="EditProfileScreen"
        component={EditProfileScreen}
        options={{ title: 'Edit profile / SensiMate' }}
      />
      <Stack.Screen
        name="GuestCompleteSignupScreen"
        component={GuestCompleteSignupScreen}
        options={{ title: 'Complete signup / SensiMate' }}
      />
    </Stack.Navigator>
  )
}

export default ProfileNavigator
