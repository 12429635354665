import React, { Dispatch, SetStateAction } from 'react'
import {
  GestureResponderEvent,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { theme } from '../../../theme/theme'
import { Gender } from '../../../src/types/coreApi-types'
import { Default, Strong } from '../../../components/Text'
import { t } from 'i18n-js'

interface GenderButtonsProps {
  setGender: Dispatch<SetStateAction<Gender | undefined>>
  gender: Gender | undefined
}

const GenderButtons: React.FC<GenderButtonsProps> = ({ setGender, gender }) => {
  const { colors } = theme
  const genderButtons = [
    {
      title: t('sign-up.female'),
      onPress: () => setGender('Female'),
      active: gender === 'Female',
    },
    {
      title: t('sign-up.male'),
      onPress: () => setGender('Male'),
      active: gender === 'Male',
    },
    {
      title: t('sign-up.non-binary'),
      onPress: () => setGender('NonBinary'),
      active: gender === 'NonBinary',
    },
  ]

  return (
    <View
      style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 6 }}
    >
      {genderButtons.map(item => (
        <TouchableOpacity
          key={item.title}
          activeOpacity={0.8}
          style={{
            borderStyle: 'solid',
            backgroundColor: item.active
              ? theme.colors.accent
              : theme.colors.bg,
            padding: 12,
            borderColor: 'rgba(0, 0, 0, 0.1)',
            borderWidth: 1,
            borderRadius: 8,
            height: 70,
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={item.onPress}
        >
          <Default
            style={{
              textAlign: 'center',
              color: item.active
                ? theme.colors['accent-text']
                : theme.colors.accent,
            }}
          >
            {item.title}
          </Default>
        </TouchableOpacity>
      ))}
    </View>
  )
}

export default GenderButtons
