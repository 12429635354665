import React, { useRef, useState } from 'react'
import { StyleSheet, TouchableOpacity, TextInput, View } from 'react-native'
import * as Text from './Text'
import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import { theme } from '../theme/theme'
import { Ionicons } from '@expo/vector-icons'
import { KeyboardListenerInputProps } from '../hooks/useKeyboardListener'

interface Props {
  placeholder?: string
  style?: React.CSSProperties
  onChangeText?: (text: string) => void
  secure?: boolean
  keyboard?: 'email-address' | 'number-pad' | 'default'
  value?: string
  autoFocus?: boolean
  error?: string
  label?: string
  disabled?: boolean
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters'
  onBlur?: () => void
  hideBorder?: boolean
  renderLeft?: JSX.Element
  numberOfLines?: number
  maxLength?: number
  keyboardListenerInputProps?: KeyboardListenerInputProps<TextInput>
}

export default function Input({
  placeholder,
  style,
  onChangeText,
  secure,
  keyboard,
  value,
  autoFocus,
  error,
  label,
  disabled,
  autoCapitalize,
  onBlur,
  hideBorder,
  renderLeft,
  numberOfLines,
  maxLength,
  keyboardListenerInputProps,
}: Props) {
  const [showing, setShowing] = useState(false)

  return (
    <View style={style as any}>
      {label && (
        <View
          style={[
            {
              marginBottom: 4,
              flexDirection: 'row',
              justifyContent: label ? 'space-between' : 'flex-end',
              alignItems: 'flex-end',
              paddingRight: 4,
            },
          ]}
        >
          {label && <Text.Default>{label}</Text.Default>}
        </View>
      )}

      <View
        style={[
          styles.container,
          hideBorder
            ? {}
            : {
                borderWidth: 1,
                borderColor: error ? theme.colors.warning : theme.colors.border,
              },
        ]}
      >
        {renderLeft && renderLeft}
        <TextInput
          ref={keyboardListenerInputProps?.ref}
          returnKeyType="done"
          textAlignVertical={'top'}
          keyboardType={keyboard ?? 'default'}
          placeholderTextColor={theme.colors.border}
          placeholder={placeholder}
          value={value}
          autoFocus={autoFocus}
          secureTextEntry={secure && !showing}
          multiline={Boolean(numberOfLines) ? true : false}
          maxLength={maxLength}
          numberOfLines={numberOfLines}
          style={[
            styles.input,
            numberOfLines ? { minHeight: 160, paddingTop: 16 } : { height: 48 },
          ]}
          autoCapitalize={autoCapitalize || 'none'}
          editable={!disabled}
          selectTextOnFocus={!disabled}
          onBlur={onBlur || keyboardListenerInputProps?.onBlur}
          onFocus={keyboardListenerInputProps?.onFocus}
          onChangeText={e => {
            if (onChangeText) onChangeText(e)
          }}
        />
        {secure && (
          <TouchableOpacity
            onPress={() => setShowing(!showing)}
            activeOpacity={0.7}
            style={{
              width: 48,
              height: '100%',
              position: 'absolute',
              right: 0,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!showing ? (
              <Ionicons name="eye-off" size={18} color="#7D869E" />
            ) : (
              <Ionicons name="eye" size={18} color="#7D869E" />
            )}
          </TouchableOpacity>
        )}
      </View>
      {error && (
        <Text.Default
          style={{
            textAlign: 'left',
            color: theme.colors.warning,
            marginTop: 6,
          }}
        >
          {error}
        </Text.Default>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: 10,
  },
  input: {
    padding: 16,
    color: theme.colors.accent,
    alignItems: 'center',
    width: '100%',
    fontSize: theme.textSizes.md,
    fontFamily: theme.fonts.regular,
  },
  clear: {
    position: 'absolute',
    right: 0,
    justifyContent: 'center',
    height: '100%',
    paddingHorizontal: 16,
  },
})
