import jwt_decode from 'jwt-decode'
import { SessionState } from '../redux/slices/sessionSlice'

export default function getCurrentProfileId(session: SessionState) {
  if (session.token) {
    const jwt: any = jwt_decode(session.token)
    if (jwt) {
      const id = jwt.sub
      if (id) {
        return id
      }
    }

    throw new Error('Invalid formatted JWT')
  }

  if (session.onTemporaryRoute && session.temporaryGuestProfile) {
    return session.temporaryGuestProfile.id
  }
  if (session.guestProfile) {
    return session.guestProfile.id
  }

  return null
  throw new Error('Tried getting Profile ID, but no Profile found.')
}
