import React, { useState } from 'react'
import { View, StyleSheet } from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { ProfileNavigatorParamList } from '../../types/NavigationTypes'
import { RouteProp } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import Page from '../../components/Page'
import { theme } from '../../theme/theme'
import { Description } from '../../components/Text'
import ProfileButton from './components/ProfileButton'
import { Linking } from 'react-native'
import {
  useDeleteUserMutation,
  useResetCacheMutation,
} from '../../redux/services/coreApi'
import { useToast } from 'react-native-toast-notifications'
import { t } from 'i18n-js'

type AboutScreenNavigationProps = NativeStackNavigationProp<
  ProfileNavigatorParamList,
  'AboutScreen'
>
type AboutScreenRouteProps = RouteProp<ProfileNavigatorParamList, 'AboutScreen'>

type Props = {
  navigation: AboutScreenNavigationProps
  route: AboutScreenRouteProps
}

export default function AboutScreen({ navigation, route }: Props) {
  const session = useSelector((state: RootState) => state.session)
  const dispatch = useDispatch()

  const [deleteUser, { isLoading: isDeletingUser }] = useDeleteUserMutation()
  const [resetCache] = useResetCacheMutation()

  const toast = useToast()

  const { colors } = theme

  const aboutButtons = [
    {
      title: t('profile.version'),
      rightText: '0.9.6',
    },
    {
      title: t('profile.build-number'),
      rightText: '9',
    },
    {
      title: t('profile.privacy-policy'),
      onPress: () => {
        Linking.openURL('https://api.sensimate.dk/privacy-policy.html')
      },
    },
    {
      title: t('profile.visit-website'),
      onPress: () => {
        Linking.openURL('https://sensimate.dk')
      },
      warning: false,
    },
  ]

  return (
    <Page loading={isDeletingUser} title={t('profile.about')}>
      <View style={styles.container}>
        <View>
          {aboutButtons.map((item, index) => (
            <ProfileButton
              key={item.title}
              title={item.title}
              onPress={item.onPress}
              rightText={item.rightText}
              warning={item.warning}
            />
          ))}
          <Description style={{ textAlign: 'center', marginTop: 16 }}>
            {t('profile.copyright')}
          </Description>
        </View>
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    flex: 1,
    marginHorizontal: -16,
  },
})
