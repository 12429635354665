import React, { Dispatch, SetStateAction } from 'react'
import { View, StyleSheet, TextInput } from 'react-native'
import { theme } from '../../../theme/theme'
import Input from '../../../components/Input'
import PasswordValidationChecks from './PasswordValidationChecks'
import { t } from 'i18n-js'
import { KeyboardListenerInputProps } from '../../../hooks/useKeyboardListener'

type PasswordInputProps = {
  password: string
  setPassword: Dispatch<SetStateAction<string>>
  confirmPassword: string
  setConfirmPassword: Dispatch<SetStateAction<string>>
  keyboardListenerInputProps?: KeyboardListenerInputProps<TextInput>
}

export default function PasswordInput({
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  keyboardListenerInputProps,
}: PasswordInputProps) {
  const { colors } = theme

  return (
    <View style={{ gap: 6 }}>
      <Input
        onChangeText={text => setPassword(text)}
        value={password}
        secure
        placeholder={t('sign-up.password')}
        autoCapitalize={'none'}
        keyboardListenerInputProps={keyboardListenerInputProps}
      />
      <Input
        onChangeText={text => setConfirmPassword(text)}
        value={confirmPassword}
        secure
        placeholder={t('sign-up.confirm-password')}
        autoCapitalize={'none'}
        keyboardListenerInputProps={keyboardListenerInputProps}
      />
      <PasswordValidationChecks
        password={password}
        confirmPassword={confirmPassword}
      />
    </View>
  )
}

const styles = StyleSheet.create({})
