import { createContext, useState } from 'react'

export const HideTabBarContext = createContext({
  setHideTabBar: (val: boolean) => {},
  hideTabBar: false,
})

export const HideTabBarProvider: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  const [hideTabBar, setHideTabBar] = useState(false)

  return (
    <HideTabBarContext.Provider value={{ hideTabBar, setHideTabBar }}>
      {children}
    </HideTabBarContext.Provider>
  )
}
