import React from 'react'
import { KeyboardAvoidingView, Platform } from 'react-native'

interface KeyboardAvoidingViewWebProps {
  keyboardOpen: boolean
  padding?: number
  children?: React.ReactNode
}

const phoneKeyboardSize = 260

const KeyboardAvoidingViewWeb: React.FC<KeyboardAvoidingViewWebProps> = ({
  keyboardOpen,
  padding = 0,
  children,
}) => {
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={{
        paddingBottom: keyboardOpen ? phoneKeyboardSize + padding : 0,
      }}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 200 : 0}
    >
      <>{children}</>
    </KeyboardAvoidingView>
  )
}

export default KeyboardAvoidingViewWeb
