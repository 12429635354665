import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import { QuestionDto } from '../../../src/types/coreApi-types'
import { theme } from '../../../theme/theme'
import Input from '../../../components/Input'
import { t } from 'i18n-js'

type Props = {
  question: QuestionDto
  answersState: string[]
  setAnswersState: Dispatch<SetStateAction<string[]>>
}

export default function TextQuestion({
  question,
  answersState,
  setAnswersState,
}: Props) {
  const { colors } = theme
  const defaultMaxLength = 255

  return (
    <>
      <Input
        hideBorder
        placeholder={t('events.enter-your-answer-here')}
        numberOfLines={10}
        maxLength={question.maximum ?? defaultMaxLength}
        onChangeText={text =>
          setAnswersState(value => {
            const newArr = [...value]
            newArr[0] = text
            return newArr
          })
        }
        value={answersState[0]}
        style={{
          backgroundColor: colors['grey-button'],
          borderRadius: 8,
          marginBottom: 10,
        }}
      />
      {/* <Description>
        {answersState[0] ? answersState[0].length : '0'} / {defaultMaxLength}{' '}
        letters
      </Description> */}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'black',
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: 5,
  },
})
