import React, { Dispatch, SetStateAction, useState } from 'react'
import { View, TouchableOpacity, StyleSheet } from 'react-native'
import { theme } from '../../../theme/theme'
import Input from '../../../components/Input'
import { Default } from '../../../components/Text'
import { t } from 'i18n-js'

type Props = {
  setAdditionalOptionText: Dispatch<SetStateAction<string>>
  additionalOptionText: string
  onBlur: (value: string) => void
  error: string
  squareMarker?: boolean
}

export default function AddOptionInput({
  setAdditionalOptionText,
  additionalOptionText,
  onBlur,
  error,
  squareMarker,
}: Props) {
  const { colors } = theme

  const [addOptionFocused, setAddOptionFocused] = useState(false)

  return (
    <View>
      <TouchableOpacity
        style={{
          padding: 8,
          paddingHorizontal: 16,
        }}
        onPress={() => {
          setAddOptionFocused(true)
        }}
      >
        <Input
          hideBorder
          renderLeft={
            <View
              style={{
                height: 22,
                width: 22,
                borderRadius: squareMarker ? 4 : 11,
                borderColor: colors.border,
                borderStyle: 'solid',
                borderWidth: 1,
                backgroundColor: colors.bg,
                alignSelf: 'center',
              }}
            ></View>
          }
          placeholder={
            addOptionFocused ? '' : t('events.enter-your-answer-here')
          }
          onChangeText={text => setAdditionalOptionText(text)}
          value={additionalOptionText}
          onBlur={() => {
            onBlur(additionalOptionText)
          }}
        />
      </TouchableOpacity>
      {error !== '' && (
        <Default style={{ color: colors.warning }}>{error}</Default>
      )}
    </View>
  )
}

const styles = StyleSheet.create({})
