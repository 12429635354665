import React, { useState } from 'react'
import { View, StyleSheet, Text, Button, Alert } from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { AuthNavigationParamList } from '../../types/NavigationTypes'
import { Link, RouteProp } from '@react-navigation/native'
import { useDispatch } from 'react-redux'
import { startSession } from '../../redux/slices/sessionSlice'
import Input from '../../components/Input'
import Page from '../../components/Page'
import PrimaryButton from '../../components/PrimaryButton'
import {
  useAuthenticateMutation,
  useChangePasswordMutation,
  useResetPasswordMutation,
  useValidateResetCodeMutation,
} from '../../redux/services/coreApi'
import LinkButton from '../../components/LinkButton'
import QuestionHeader from '../Survey/components/QuestionHeader'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { useToast } from 'react-native-toast-notifications'
import { t } from 'i18n-js'
import {
  errorToastOptions,
  successContrastToastOptions,
  successToastOptions,
} from '../../utils/toastOptions'
import { Default } from '../../components/Text'
import ResetPasswordFlow from './components/ResetPasswordFlow'
import { validateResetPassword } from '../../utils/validation'

type ResetPasswordScreenNavigationProps = NativeStackNavigationProp<
  AuthNavigationParamList,
  'ResetPasswordScreen'
>
type ResetPasswordScreenRouteProps = RouteProp<
  AuthNavigationParamList,
  'ResetPasswordScreen'
>

type Props = {
  navigation: ResetPasswordScreenNavigationProps
  route: ResetPasswordScreenRouteProps
}

export default function ResetPasswordScreen({ navigation, route }: Props) {
  const toast = useToast()

  const [screen, setScreen] = useState(1)
  const [emailCodeError, setEmailCodeError] = useState(false)
  const [email, setEmail] = useState('')
  const [emailCode, setEmailCode] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const screens = 3

  const [resetPassword, { isLoading: isResettingPassword }] =
    useResetPasswordMutation()

  const [validateResetCode, { isLoading: isValidatingResetCode }] =
    useValidateResetCodeMutation()

  const [changePassword, { isLoading: isChangingPassword }] =
    useChangePasswordMutation()

  return (
    <Page
      screens={screens}
      currentScreen={screen}
      screensText={t('forgot-password.step')}
    >
      <View style={styles.container}>
        <View>
          <ResetPasswordFlow
            email={email}
            setEmail={setEmail}
            emailCode={emailCode}
            setEmailCode={setEmailCode}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            emailCodeError={emailCodeError}
            loading={isValidatingResetCode}
            screen={screen}
          />
        </View>

        <PrimaryButton
          title={t('general.continue')}
          disabled={
            isResettingPassword ||
            isChangingPassword ||
            isValidatingResetCode ||
            !validateResetPassword(
              screen,
              email,
              emailCode,
              password,
              confirmPassword
            )
          }
          loading={
            isResettingPassword || isChangingPassword || isValidatingResetCode
          }
          onPress={() => {
            if (screen === 1) {
              resetPassword({ email })
                .unwrap()
                .then(() => {
                  setScreen(value => value + 1)
                })
              return
            }
            if (screen === 2) {
              validateResetCode({ code: emailCode, email })
                .unwrap()
                .then(res => {
                  setScreen(value => value + 1)
                })
                .catch(() => {
                  setEmailCode('')
                  setEmailCodeError(true)
                })
              return
            }
            if (screen === 3) {
              changePassword({ code: emailCode, email, password })
                .unwrap()
                .then(res => {
                  toast.show(t('success.password-changed'), successToastOptions)
                  navigation.navigate('LoginScreen')
                })
                .catch(() => {
                  toast.show(
                    t('validation.something-went-wrong'),
                    errorToastOptions
                  )
                  navigation.navigate('LoginScreen')
                })
            }
          }}
        />
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 20,
    justifyContent: 'space-between',
  },
  verticallySpaced: {
    paddingTop: 4,
    paddingBottom: 4,
  },
})
