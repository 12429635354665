import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit'
import { ToastType } from 'react-native-toast-notifications'

let toast: ToastType | null = null

export const bindHooksToRTKErrorLogger = (_toast: any) => {
  toast = _toast
}

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => next => action => {
    if (isRejectedWithValue(action)) {
      if (toast) {
        let errorText
        if (action.payload.status !== 401) {
          if (action.payload.data.message) {
            errorText = action.payload.data.message
          } else {
            errorText = 'Noget gik galt, prøv igen'
          }

          if (action.payload.status === 500) {
            errorText = 'Intern server-fejl'
          }
        }
      }
    }

    return next(action)
  }
