import React, { useContext, useEffect, useState } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { RouteProp, TabRouter } from '@react-navigation/native'
import Input from '../../components/Input'
import { SurveyNavigatorParamList } from '../../types/NavigationTypes'
import PrimaryButton from '../../components/PrimaryButton'
import { Image } from 'expo-image'
import Page from '../../components/Page'
import { useGetSurveyQuery } from '../../redux/services/coreApi'
import Badge from '../../components/Badge'
import {
  Description,
  StrongDescription,
  StrongHeader,
} from '../../components/Text'
import { theme } from '../../theme/theme'
import SurveyOverviewSection from './components/SurveyOverviewSection'
import moment from 'moment'
import { formatEventDateAndTime } from '../../utils/formatEventDateAndTime'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons/faUserGroup'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { Ionicons } from '@expo/vector-icons'
import LanguagePicker from '../../components/LanguagePicker'
import { LocaleContext } from '../../contexts/LocaleContext'
import { t } from 'i18n-js'
import getCurrentProfileId from '../../utils/getCurrentProfileId'

type SurveyOverviewScreenNavigationProps = NativeStackNavigationProp<
  SurveyNavigatorParamList,
  'SurveyOverviewScreen'
>
type SurveyOverviewScreenRouteProps = RouteProp<
  SurveyNavigatorParamList,
  'SurveyOverviewScreen'
>

type Props = {
  navigation: SurveyOverviewScreenNavigationProps
  route: SurveyOverviewScreenRouteProps
}

export default function SurveyOverviewScreen({ navigation, route }: Props) {
  const { surveyId, eventCode } = route.params
  const { locale, setLocale } = useContext(LocaleContext)

  const session = useSelector((state: RootState) => state.session)

  const profileId = getCurrentProfileId(session)

  const {
    data: survey,
    isFetching,
    error,
  } = useGetSurveyQuery(
    { surveyId, locale },
    { refetchOnMountOrArgChange: true }
  )
  const [isAllergic, setIsAllergic] = useState<boolean>(true)

  useEffect(() => {
    if (!survey) return
    const correctLocaleSurvey = survey.event.surveys?.find(
      item => item.lang === locale
    )
    if (!correctLocaleSurvey) return
    navigation.navigate('SurveyOverviewScreen', {
      ...route.params,
      surveyId: correctLocaleSurvey.id,
    })
  }, [locale])

  const continuedSurvey = survey
    ? survey.event.eventCodes.length !== 0 ||
      survey.questions.some(q => q.answers.length > 0)
    : false

  useEffect(() => {
    if (survey?.allergens.length === 0 || continuedSurvey) {
      setIsAllergic(false)
    }
  }, [survey, continuedSurvey])

  const isHappeningNow = moment().isBetween(
    survey?.event.startsAt,
    survey?.event.expiresAt
  )

  const isSurveyExpired = false

  const isSurveyUpcoming = moment().isBefore(survey?.event.startsAt)

  const isSurveyCodeRequired = survey?.event.codeRequired

  useEffect(() => {
    if (error && 'data' in error && error.status === 404) {
      navigation.navigate('HomeScreen')
    }
  }, [error])

  return (
    <Page
      loading={!survey || isFetching}
      backButtonDisabled={!profileId}
      rightRender={
        <View style={{ zIndex: 99, flexDirection: 'row', gap: 4 }}>
          {isSurveyCodeRequired ? (
            <Badge>
              {isSurveyExpired
                ? t('events.event-closed')
                : `${survey.totalEventCodes - survey.usedEventCodes} / ${
                    survey.totalEventCodes
                  } ${t('events.spots-left')}`}
            </Badge>
          ) : undefined}

          <LanguagePicker
            availableLangs={survey?.event.surveys?.map(item => item.lang)}
            currentLang={survey?.lang}
          />
        </View>
      }
    >
      {survey && (
        <>
          <View style={styles.container}>
            <View
              style={{
                flexDirection: 'row',
                marginVertical: 16,
                gap: 16,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Image
                style={styles.image}
                source={{
                  uri: survey.introImage,
                }}
              />
              <View style={{ width: '75%' }}>
                <StrongDescription
                  style={{
                    color: theme.colors.secondary,
                    fontSize: theme.textSizes.sm,
                  }}
                >
                  {!isSurveyUpcoming
                    ? t('events.happening-now').toUpperCase()
                    : ''}
                </StrongDescription>

                <StrongHeader style={{ marginTop: 2 }} numberOfLines={2}>
                  {survey.title}
                </StrongHeader>
                <Description
                  style={{
                    color: theme.colors.description,
                    fontSize: theme.textSizes.sm,
                    marginTop: 2,
                  }}
                >
                  {formatEventDateAndTime(
                    survey?.event.startsAt,
                    survey?.event.expiresAt
                  )}
                </Description>
              </View>
            </View>

            {survey.productDescription && (
              <SurveyOverviewSection
                title={t('events.about-the-event')}
                description={survey.productDescription}
                toggleNumberOfDescriptionLines
              />
            )}
            {survey.showAllergens && (
              <SurveyOverviewSection
                title={
                  survey.allergens.length > 0
                    ? t('events.allergens')
                    : t('events.no-allergens')
                }
                description={survey.allergens}
              />
            )}
            {survey.address && (
              <SurveyOverviewSection
                title={t('events.location')}
                description={survey.address}
              />
            )}
          </View>

          {survey.showAllergens && !isSurveyUpcoming && (
            <TouchableOpacity
              activeOpacity={0.5}
              onPress={() => setIsAllergic(!isAllergic)}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                gap: 10,
                marginBottom: 14,
              }}
            >
              <View
                style={{
                  height: 24,
                  width: 24,
                  borderRadius: 6,
                  borderColor: theme.colors.accent,
                  borderWidth: 1.5,
                  backgroundColor: isAllergic
                    ? theme.colors.bg
                    : theme.colors.accent,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Ionicons name="checkmark" size={14} color="#fff" />
              </View>
              <Text
                style={{
                  fontFamily: theme.fonts.semi,
                  color: theme.colors.accent,
                }}
              >
                {t('events.i-am-not-allergic')}
              </Text>
            </TouchableOpacity>
          )}

          {!isSurveyUpcoming && (
            <View style={{ gap: 6 }}>
              {!profileId ? (
                // QR Code flow button
                <PrimaryButton
                  title={t('events.join-survey')}
                  disabled={survey.showAllergens && isAllergic}
                  onPress={() => {
                    if (survey.event.codeRequired && !eventCode) {
                      navigation.navigate('SurveyCodeScreen', {
                        surveyId,
                        temporary: true,
                      })
                    } else {
                      navigation.navigate('TemporaryGuestSignupScreen', {
                        surveyId,
                        eventId: survey.eventId,
                        temporary: true,
                        eventCode,
                      })
                    }
                  }}
                />
              ) : (
                <>
                  <PrimaryButton
                    title={
                      continuedSurvey
                        ? t('events.continue-survey')
                        : t('events.join-survey')
                    }
                    disabled={survey.showAllergens && isAllergic}
                    onPress={() => {
                      if (continuedSurvey) {
                        navigation.navigate('SurveyReadyScreen', {
                          surveyId,
                          temporary: false,
                        })
                      } else {
                        if (
                          survey.event.codeRequired &&
                          survey.event.eventCodes.length === 0
                        ) {
                          // Go to code if Code is required
                          navigation.navigate('SurveyCodeScreen', {
                            surveyId,
                            temporary: false,
                          })
                        } else {
                          // If no code is required, go to Survey screen
                          navigation.navigate('SurveyReadyScreen', {
                            surveyId,
                            temporary: false,
                          })
                        }
                      }
                    }}
                  />
                  <PrimaryButton
                    secondary
                    style={{
                      borderWidth: 1,
                      borderColor: theme.colors.accent,
                      backgroundColor: theme.colors.bg,
                    }}
                    onPress={() => {
                      if (survey.event.codeRequired) {
                        navigation.navigate('SurveyCodeScreen', {
                          surveyId,
                          temporary: true,
                        })
                      } else {
                        navigation.navigate('TemporaryGuestSignupScreen', {
                          surveyId,
                          eventId: survey.eventId,
                          temporary: true,
                        })
                      }
                    }}
                    disabled={survey.showAllergens && isAllergic}
                    title={t('events.let-my-friend-take-survey')}
                    renderLeft={
                      <View>
                        <FontAwesomeIcon
                          icon={faUserGroup}
                          color={theme.colors.accent}
                        />
                      </View>
                    }
                  />
                </>
              )}
            </View>
          )}
        </>
      )}
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  image: {
    borderRadius: 12,
    width: '22%',
    aspectRatio: 1,
  },
})
