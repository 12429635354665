import React, { useContext, useEffect, useRef, useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { RouteProp } from '@react-navigation/native'
import Input from '../../components/Input'
import {
  HomeNavigatorParamList,
  SurveyNavigatorParamList,
} from '../../types/NavigationTypes'
import PrimaryButton from '../../components/PrimaryButton'
import { Image } from 'expo-image'
import Page from '../../components/Page'
import { theme } from '../../theme/theme'
import { t } from 'i18n-js'
import {
  useAssignCodeMutation,
  useGetSurveyQuery,
  useValidateCodeMutation,
} from '../../redux/services/coreApi'
import { Description, Header, StrongHeader } from '../../components/Text'
import CodeInput from '../../components/CodeInput'
import { LocaleContext } from '../../contexts/LocaleContext'

type SurveyCodeScreenNavigationProps = NativeStackNavigationProp<
  SurveyNavigatorParamList,
  'SurveyCodeScreen'
>
type SurveyCodeScreenRouteProps = RouteProp<
  SurveyNavigatorParamList,
  'SurveyCodeScreen'
>

type Props = {
  navigation: SurveyCodeScreenNavigationProps
  route: SurveyCodeScreenRouteProps
}

export default function SurveyCodeScreen({ navigation, route }: Props) {
  const { surveyId, temporary } = route.params
  const { locale, setLocale } = useContext(LocaleContext)

  const { data: survey } = useGetSurveyQuery({ surveyId, locale })
  const { colors } = theme
  const [validateCode, { isLoading: isValidatingCode }] =
    useValidateCodeMutation()

  const [assignCode, { isLoading: isAssigningCode }] = useAssignCodeMutation()

  const [code, setCode] = useState('')
  const [error, setError] = useState(false)

  const codeLength = 4

  useEffect(() => {
    if (survey) {
      if (code.length === codeLength) {
        setError(false)
        if (!temporary) {
          assignCode({ code, eventId: survey.eventId })
            .unwrap()
            .then(res => {
              navigation.navigate('SurveyReadyScreen', {
                surveyId,
                temporary: temporary,
              })
            })
            .catch(() => {
              setCode('')
              setError(true)
            })
        } else {
          validateCode({ code, eventId: survey.eventId })
            .unwrap()
            .then(res => {
              navigation.navigate('TemporaryGuestSignupScreen', {
                surveyId,
                eventId: survey.eventId,
                temporary: temporary,
                eventCode: code,
              })
            })
            .catch(() => {
              setCode('')
              setError(true)
            })
        }
      }
    }
  }, [code, survey])

  return (
    <Page>
      <View style={styles.container}>
        <View style={{ alignItems: 'center', alignContent: 'center', flex: 1 }}>
          <View>
            <Header
              style={{ textAlign: 'center', fontSize: theme.textSizes.xxxl }}
            >
              {t('events.enter-sample-code')}
            </Header>
          </View>
          <Description
            style={{ textAlign: 'center', marginTop: 6, marginBottom: 30 }}
          >
            {t('events.enter-sample-code-description')}
          </Description>
          <CodeInput
            codeLength={codeLength}
            code={code}
            setCode={setCode}
            error={error}
            errorMessage={t('validation.invalid-sample-code')}
            loading={isValidatingCode || isAssigningCode}
          />
        </View>
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  image: {
    width: 80,
    height: 80,
    borderRadius: 40,
  },
  hiddenCodeInput: {
    position: 'absolute',
    height: 0,
    width: 0,
    opacity: 0,
  },
  inputsContainer: {
    width: '90%',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 10,
  },
  inputContainer: {
    borderColor: '#cccccc',
    borderWidth: 2,
    borderRadius: 4,
    padding: 12,
    width: 45,
    textAlign: 'center',
  },
  inputContainerFocused: {
    borderColor: '#2F3B45',
    borderWidth: 2,
    borderRadius: 4,
    padding: 12,
  },
  inputText: {
    fontSize: theme.textSizes.xxxl,
    fontFamily: theme.fonts.medium,
  },
})
