import React, { useEffect } from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import HomeTabNavigation from './HomeTabNavigation'
import AuthNavigation from './AuthNavigation'
import { NavigationParamList } from '../types/NavigationTypes'

const Stack = createNativeStackNavigator<NavigationParamList>()

const Navigation: React.FC = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="Auth" component={AuthNavigation} />
      <Stack.Screen name="Home" component={HomeTabNavigation} />
    </Stack.Navigator>
  )
}

export default Navigation
