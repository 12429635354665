import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { View, StyleSheet } from 'react-native'
import { AnswerDto, QuestionDto } from '../../../src/types/coreApi-types'
import { theme } from '../../../theme/theme'
import Input from '../../../components/Input'
import { Default, Description, Strong } from '../../../components/Text'
import { Slider } from '@miblanchard/react-native-slider'

type Props = {
  question: QuestionDto
  answersState: string[]
  setAnswersState: Dispatch<SetStateAction<string[]>>
  columnAnswersState: string[]
  setColumnAnswersState: Dispatch<SetStateAction<string[]>>
}

export default function BatterySliderQuestion({
  question,
  answersState,
  setAnswersState,
  columnAnswersState,
  setColumnAnswersState,
}: Props) {
  const { colors } = theme

  const setAnswer = (answer: string, columnAnswer: string) => {
    const answerWithKey = answer + ' KEY:' + columnAnswer
    const columnAnswerWithKey = columnAnswer + ' KEY:' + answer

    setColumnAnswersState(value => {
      const newArr = [...value]
      const answerIndex = newArr.findIndex(item =>
        item.includes('KEY:' + answer)
      )
      if (answerIndex !== -1) {
        newArr[answerIndex] = columnAnswerWithKey
        return newArr
      } else {
        return [...value, columnAnswerWithKey]
      }
    })
    setAnswersState(value => {
      const newArr = [...value]
      const columnAnswerIndex = newArr.findIndex(item => item.includes(answer))
      if (columnAnswerIndex !== -1) {
        newArr[columnAnswerIndex] = answerWithKey
        return newArr
      } else {
        return [...value, answerWithKey]
      }
    })
  }

  const [active, setActive] = useState([''])

  return (
    <>
      {question.options.map((option, index) => {
        const initialIndex = columnAnswersState.findIndex(item =>
          item.includes('KEY:' + option)
        )
        let initialValue =
          initialIndex !== -1
            ? parseFloat(columnAnswersState[initialIndex].split('KEY:')[0])
            : ((question.minimum ?? 0) + (question.maximum ?? 10)) / 2

        const isActive =
          active.includes(option) ||
          answersState.map(item => item.split(' KEY')[0]).includes(option)
        return (
          <View
            key={option}
            style={{
              justifyContent: 'space-between',
              marginBottom: index + 1 !== question.options.length ? 65 : 0,
            }}
          >
            <Strong>{option}</Strong>
            <Slider
              minimumValue={question.minimum ?? 0}
              maximumValue={question.maximum ?? 10}
              minimumTrackTintColor={
                isActive ? colors.accent : colors['grey-button']
              }
              maximumTrackTintColor={colors['grey-button']}
              thumbStyle={{
                backgroundColor: colors.accent,
                borderColor: '#FFFFFF',
                borderStyle: 'solid',
                borderWidth: 2,
                width: 28,
                height: 28,
                borderRadius: 14,
              }}
              trackStyle={{ height: 12, borderRadius: 6 }}
              onSlidingStart={() =>
                setActive(value => [...new Set(value).values(), option])
              }
              onSlidingComplete={e => {
                setAnswer(option, e.toString())
              }}
              value={initialValue}
            />
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              {question.columnOptions.map((columnOption, index) => {
                return (
                  <Description key={columnOption}>{columnOption}</Description>
                )
              })}
            </View>
          </View>
        )
      })}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'black',
  },
  image: {
    width: 50,
    height: 50,
    borderRadius: 5,
  },
})
