import React, { useState, Dispatch, SetStateAction, useEffect } from 'react'
import { View, StyleSheet, TextInput } from 'react-native'
import Input from '../../../components/Input'
import GenderButtons from './GenderButtons'
import { Gender } from '../../../src/types/coreApi-types'
import CodeInput from '../../../components/CodeInput'
import QuestionHeader from '../../Survey/components/QuestionHeader'
import { Description, Strong } from '../../../components/Text'
import { Ionicons } from '@expo/vector-icons'
import { theme } from '../../../theme/theme'
import { KeyboardListenerInputProps } from '../../../hooks/useKeyboardListener'
import { t } from 'i18n-js'

type GuestSignupFlowProps = {
  birthdate: string | undefined
  setBirthDate: Dispatch<SetStateAction<string | undefined>>
  gender: Gender | undefined
  setGender: Dispatch<SetStateAction<Gender | undefined>>
  zipCode: string
  setZipCode: Dispatch<SetStateAction<string>>
  update?: boolean
  keyboardListenerInputProps?: KeyboardListenerInputProps<TextInput>
}

export default function GuestSignupFlow({
  birthdate,
  setBirthDate,
  gender,
  setGender,
  zipCode,
  setZipCode,
  update,
  keyboardListenerInputProps,
}: GuestSignupFlowProps) {
  const { colors } = theme
  const [birthdateError, setBirthdateError] = useState<
    'none' | '18' | '100' | 'digits' | 'length'
  >('none')
  const [zipCodeError, setZipCodeError] = useState<
    'none' | 'digits' | 'length'
  >('none')
  const codeLength = 4

  useEffect(() => {
    if (!birthdate) return
    setBirthdateError('none')

    if (
      new Date(
        new Date().getTime() - new Date(birthdate).getTime()
      ).getUTCFullYear() -
        1970 <
      18
    ) {
      return setBirthdateError('18')
    }

    if (
      new Date(
        new Date().getTime() - new Date(birthdate).getTime()
      ).getUTCFullYear() -
        1970 >
      100
    ) {
      return setBirthdateError('100')
    }

    if (birthdate.match(/\D/)) {
      return setBirthdateError('digits')
    }

    if (birthdate.length !== codeLength) {
      return setBirthdateError('length')
    }
  }, [birthdate])

  useEffect(() => {
    if (!zipCode) return
    setZipCodeError('none')

    if (zipCode.match(/\D/)) {
      return setZipCodeError('digits')
    }

    if (zipCode.length !== codeLength) {
      return setZipCodeError('length')
    }
  }, [zipCode])

  return (
    <View style={{ gap: 30, paddingBottom: 30 }}>
      <View>
        <QuestionHeader question={t('sign-up.birthyear')} strong />
        <Input
          onChangeText={text => setBirthDate(text)}
          value={birthdate}
          placeholder={t('sign-up.birthyear')}
          keyboardListenerInputProps={keyboardListenerInputProps}
          error={
            birthdateError === '18'
              ? t('validation.18-or-higher')
              : birthdateError === '100'
              ? t('validation.no-older-than-100')
              : birthdateError === 'digits'
              ? t('validation.must-be-digits')
              : birthdateError === 'length'
              ? t('validation.must-be-4-digits')
              : ''
          }
        />
      </View>

      <View>
        <QuestionHeader question={t('sign-up.gender')} strong optional />
        <GenderButtons setGender={setGender} gender={gender} />
      </View>

      <View>
        <QuestionHeader question={t('sign-up.post-code')} strong optional />
        <Input
          onChangeText={text => setZipCode(text)}
          value={zipCode}
          placeholder={t('sign-up.post-code')}
          keyboardListenerInputProps={keyboardListenerInputProps}
          error={
            zipCodeError === 'digits'
              ? t('validation.must-be-digits')
              : zipCodeError === 'length'
              ? t('validation.must-be-4-digits')
              : ''
          }
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 40,
    padding: 12,
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
})
