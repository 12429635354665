import React from 'react'
import { GestureResponderEvent, TouchableOpacity, Platform } from 'react-native'
import { theme } from '../theme/theme'
import InnerButton from './InnerButton'
import { TextStyle } from 'react-native'

interface PrimaryButtonProps {
  title: string
  onPress?: (event: GestureResponderEvent) => void
  secondary?: boolean
  tertiary?: boolean
  disabled?: boolean
  loading?: boolean
  style?: React.CSSProperties
  renderRight?: JSX.Element
  renderLeft?: JSX.Element
  fontSize?: number
  rounded?: boolean
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  textStyle?: TextStyle
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  title,
  onPress,
  secondary,
  tertiary,
  disabled,
  loading,
  style,
  renderRight,
  renderLeft,
  fontSize,
  rounded,
  justifyContent,
  textStyle,
}) => {
  const { colors } = theme

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      disabled={disabled}
      style={[
        {
          backgroundColor: secondary
            ? colors['grey-button']
            : tertiary
            ? colors.bg
            : colors.accent,
          height: 50,
          borderRadius: rounded ? 25 : 8,
          paddingHorizontal: 16,
          opacity: disabled ? 0.3 : 1,
          justifyContent: 'center',
        },
        style as any,
      ]}
      onPress={onPress}
    >
      <InnerButton
        loading={loading}
        justifyContent={justifyContent}
        renderLeft={renderLeft}
        secondary={secondary}
        tertiary={tertiary}
        fontSize={fontSize}
        title={title}
        renderRight={renderRight}
        textStyle={textStyle}
      />
    </TouchableOpacity>
  )
}

export default PrimaryButton
