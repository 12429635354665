import moment from 'moment'
import { useContext } from 'react'
import { LocaleContext } from '../contexts/LocaleContext'
import { monthToUpperCase } from './monthToUpperCase'

export function formatEventDateAndTime(start?: string, end?: string) {
  if (!start || !end) return
  const isSurveyOneDay = moment(start).isSame(end, 'day') as boolean

  const { locale, setLocale } = useContext(LocaleContext)

  if (isSurveyOneDay) {
    return `${monthToUpperCase(
      moment(start).locale(locale).format('DD MMM, YYYY')
    )}`
  } else {
    return `${monthToUpperCase(
      moment(start).locale(locale).format(`DD MMM`)
    )} - ${monthToUpperCase(moment(end).locale(locale).format(`DD MMM, YYYY`))}`
  }
}
