import React, {
  useState,
  useRef,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react'
import {
  GestureResponderEvent,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  KeyboardTypeOptions,
} from 'react-native'
import { theme } from '../theme/theme'
import { Strong } from './Text'
import { ActivityIndicator } from 'react-native'
import { KeyboardListenerInputProps } from '../hooks/useKeyboardListener'

interface CodeInputProps {
  codeLength: number
  code: string
  setCode: Dispatch<SetStateAction<string>>
  error?: boolean
  errorMessage?: string
  numeric?: boolean
  loading?: boolean
  keyboardListenerInputProps?: KeyboardListenerInputProps<TextInput>
}

const CodeInput: React.FC<CodeInputProps> = ({
  codeLength,
  code,
  setCode,
  error,
  errorMessage,
  numeric,
  loading,
  keyboardListenerInputProps,
}) => {
  const { colors } = theme

  const codeDigitsArray = new Array(codeLength).fill(0)

  const toDigitInput = (_value: number, idx: number) => {
    const emptyInputChar = ' '
    const digit = code[idx] || emptyInputChar

    const isCurrentDigit = idx === code.length
    const isLastDigit = idx === codeLength - 1
    const isCodeFull = code.length === codeLength

    const isFocused =
      isCurrentDigit || (isLastDigit && isCodeFull) || isCodeFull

    const containerStyle = isFocused
      ? { ...styles.inputContainer, ...styles.inputContainerFocused }
      : styles.inputContainer

    return (
      <View key={idx} style={[containerStyle]}>
        <Text style={styles.inputText}>{digit}</Text>
      </View>
    )
  }

  return (
    <View style={{ width: '100%', alignItems: 'center' }}>
      <TextInput
        ref={keyboardListenerInputProps?.ref}
        value={code}
        onChangeText={text => {
          if (numeric) {
            const newText = text.replace(/[^0-9]/g, '')
            setCode(newText)
          } else {
            setCode(text.toLocaleUpperCase())
          }
        }}
        autoComplete="off"
        autoCorrect={false}
        onBlur={keyboardListenerInputProps?.onBlur}
        onFocus={keyboardListenerInputProps?.onFocus}
        keyboardType={numeric ? 'number-pad' : 'default'}
        returnKeyType="done"
        maxLength={codeLength}
        style={styles.hiddenCodeInput}
        editable={true}
        autoCapitalize={numeric ? 'none' : 'characters'}
        autoFocus={true}
        caretHidden
      />
      <Pressable style={styles.inputsContainer}>
        {codeDigitsArray.map(toDigitInput)}
      </Pressable>
      {errorMessage && error && (
        <Strong
          style={{
            color: colors.warning,
            position: 'absolute',
            top: 80,
          }}
        >
          {errorMessage}
        </Strong>
      )}
      {loading && (
        <ActivityIndicator
          size="large"
          color={colors.accent}
          style={{
            position: 'absolute',
            top: 80,
          }}
        />
      )}
    </View>
  )
}

export default CodeInput

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  image: {
    width: 80,
    height: 80,
    borderRadius: 40,
  },
  hiddenCodeInput: {
    position: 'absolute',
    height: 80,
    width: '100%',
    opacity: 0,
    backgroundColor: 'black',
    zIndex: 10,
  },
  inputsContainer: {
    width: '90%',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 6,
  },
  inputContainer: {
    borderColor: theme.colors.border,
    borderWidth: 1,
    borderRadius: 8,
    width: 52,
    height: 66,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  inputContainerFocused: {
    borderColor: theme.colors.accent,
    borderWidth: 2,
    padding: 12,
  },
  inputText: {
    fontSize: theme.textSizes.xxxl,
    fontFamily: theme.fonts.regular,
  },
})
