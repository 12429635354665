import React, { useState } from 'react'
import { View, StyleSheet, Text, Button, Alert } from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import {
  AuthNavigationParamList,
  HomeNavigatorParamList,
} from '../../types/NavigationTypes'
import type { RouteProp } from '@react-navigation/native'
import { useDispatch } from 'react-redux'
import Input from '../../components/Input'
import Page from '../../components/Page'
import PrimaryButton from '../../components/PrimaryButton'
import {
  useCreateUserMutation,
  useValidateEmailMutation,
} from '../../redux/services/coreApi'
import 'react-native-get-random-values'
import { v4 as uuidv4 } from 'uuid'
import { startSession } from '../../redux/slices/sessionSlice'
import GuestSignupFlow from './components/GuestSignupFlow'
import { validateGuestSignup, validateUserSignup } from '../../utils/validation'
import UserSignupFlow from './components/UserSignupFlow'
import { uuid } from '../../utils/generateUuid'
import { useToast } from 'react-native-toast-notifications'
import KeyboardAvoidingViewWeb from '../../components/KeyboardAvoidingViewWeb'
import { useKeyboardListener } from '../../hooks/useKeyboardListener'
import { t } from 'i18n-js'

type UserSignupScreenNavigationProps = NativeStackNavigationProp<
  AuthNavigationParamList,
  'UserSignupScreen'
>
type UserSignupScreenRouteProps = RouteProp<
  AuthNavigationParamList,
  'UserSignupScreen'
>

type Props = {
  navigation: UserSignupScreenNavigationProps
  route: UserSignupScreenRouteProps
}

export default function UserSignupScreen({ navigation, route }: Props) {
  const dispatch = useDispatch()
  const [validateEmail, { isLoading: isValidatingEmail }] =
    useValidateEmailMutation()

  const [errorMessage, setErrorMessage] = useState('')

  const [birthdate, setBirthdate] = useState<string>()
  const [gender, setGender] = useState<
    'Female' | 'Male' | 'NonBinary' | undefined
  >()
  const [zipCode, setZipCode] = useState('')
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [screen, setScreen] = useState(1)
  const screens = 4
  const toast = useToast()

  const [createUser, { isLoading: isCreatingUser }] = useCreateUserMutation()

  const { inputProps, keyboardOpen } = useKeyboardListener()

  async function completeSignup() {
    if (!birthdate) return
    const id = uuid()
    const res = await createUser({
      fullName,
      email,
      password,
      profile: { birthdate: new Date(birthdate).toISOString(), gender, id },
    }).unwrap()

    dispatch(
      startSession({
        token: res.token,
        refreshToken: res.refreshToken,
      })
    )

    navigation.navigate('Home')
  }

  return (
    <Page
      screensText={t('sign-up.title')}
      overwriteBackButton={
        screen === 1
          ? undefined
          : () => {
              setScreen(screen - 1)
            }
      }
    >
      <View style={styles.container}>
        <View>
          {screen === 1 && (
            <GuestSignupFlow
              birthdate={birthdate}
              setBirthDate={setBirthdate}
              gender={gender}
              setGender={setGender}
              zipCode={zipCode}
              setZipCode={setZipCode}
              keyboardListenerInputProps={inputProps}
            />
          )}
          <UserSignupFlow
            fullName={fullName}
            setFullName={setFullName}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            screen={screen - 1}
            errorMessage={errorMessage}
            keyboardListenerInputProps={inputProps}
          />
        </View>

        <KeyboardAvoidingViewWeb keyboardOpen={keyboardOpen}>
          <View style={{ flexDirection: 'row', flexWrap: 'nowrap', gap: 6 }}>
            <PrimaryButton
              title={t('general.continue')}
              style={{ flex: 4 }}
              disabled={
                isCreatingUser ||
                !birthdate ||
                !validateGuestSignup(birthdate, gender, zipCode) ||
                !validateUserSignup(
                  screen - 1,
                  fullName,
                  email,
                  password,
                  confirmPassword
                )
              }
              loading={isCreatingUser || isValidatingEmail}
              onPress={async () => {
                setErrorMessage('')
                if (screen === 3) {
                  const res = await validateEmail({ email: email }).unwrap()
                  if (res.emailInUse) {
                    setErrorMessage(t('validation.email-already-in-use'))
                    return
                  }
                }
                if (screen !== screens) return setScreen(value => value + 1)
                completeSignup()
              }}
            />
          </View>
        </KeyboardAvoidingViewWeb>
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 40,
    justifyContent: 'space-between',
    flex: 1,
  },
})
