import React, { useState } from 'react'
import {
  View,
  StyleSheet,
  Text,
  Button,
  Alert,
  TouchableOpacity,
} from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import {
  AuthNavigationParamList,
  HomeNavigatorParamList,
} from '../../types/NavigationTypes'
import type { RouteProp } from '@react-navigation/native'
import { useDispatch } from 'react-redux'
import { startSession } from '../../redux/slices/sessionSlice'
import Input from '../../components/Input'
import Page from '../../components/Page'
import PrimaryButton from '../../components/PrimaryButton'
import { useCreateProfileMutation } from '../../redux/services/coreApi'
import 'react-native-get-random-values'
import { v4 as uuidv4 } from 'uuid'
import GuestSignupFlow from './components/GuestSignupFlow'
import { validateGuestSignup } from '../../utils/validation'
import { uuid } from '../../utils/generateUuid'
import { useKeyboardListener } from '../../hooks/useKeyboardListener'
import KeyboardAvoidingViewWeb from '../../components/KeyboardAvoidingViewWeb'
import { t } from 'i18n-js'

type GuestSignupScreenNavigationProps = NativeStackNavigationProp<
  AuthNavigationParamList,
  'GuestSignupScreen'
>
type GuestSignupScreenRouteProps = RouteProp<
  AuthNavigationParamList,
  'GuestSignupScreen'
>

type Props = {
  navigation: GuestSignupScreenNavigationProps
  route: GuestSignupScreenRouteProps
}

export default function GuestSignupScreen({ navigation, route }: Props) {
  const dispatch = useDispatch()
  const [birthdate, setBirthdate] = useState<string>()
  const [gender, setGender] = useState<
    'Female' | 'Male' | 'NonBinary' | undefined
  >()
  const surveyId = route.params?.surveyId
  const [zipCode, setZipCode] = useState('')

  const [createProfile, { isLoading: isCreatingProfile }] =
    useCreateProfileMutation()

  const { inputProps, keyboardOpen } = useKeyboardListener()

  async function signInAsGuest(skipZipCode?: boolean) {
    if (!birthdate) return
    const newUuid = uuid()
    await createProfile({
      birthdate: new Date(birthdate).toISOString(),
      id: newUuid,
      gender,
      zipCode: skipZipCode ? '' : zipCode,
    }).unwrap()

    dispatch(
      startSession({
        guestProfile: {
          id: newUuid,
          zipCode: skipZipCode ? '' : zipCode,
          birthdate: new Date(birthdate).toISOString(),
          gender: gender || null,
        },
      })
    )

    if (surveyId) {
      navigation.navigate('SurveyOverviewScreen', {
        surveyId,
      })
    } else {
      navigation.navigate('Home')
    }
  }

  return (
    <Page screensText={t('welcome.continue-without-account')}>
      <View style={styles.container}>
        <View>
          <GuestSignupFlow
            birthdate={birthdate}
            setBirthDate={setBirthdate}
            gender={gender}
            setGender={setGender}
            zipCode={zipCode}
            setZipCode={setZipCode}
            keyboardListenerInputProps={inputProps}
          />
        </View>

        <KeyboardAvoidingViewWeb keyboardOpen={keyboardOpen}>
          <View style={{ flexDirection: 'row', flexWrap: 'nowrap', gap: 6 }}>
            <PrimaryButton
              title={t('general.continue')}
              style={{ flex: 4 }}
              disabled={
                isCreatingProfile ||
                !birthdate ||
                !validateGuestSignup(birthdate, gender, zipCode)
              }
              loading={isCreatingProfile}
              onPress={() => {
                signInAsGuest()
              }}
            />
          </View>
        </KeyboardAvoidingViewWeb>
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    marginTop: 40,
    justifyContent: 'space-between',
    flex: 1,
    paddingBottom: 20,
  },
})
