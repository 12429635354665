import React, { Dispatch, SetStateAction } from 'react'
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native'
import { QuestionDto } from '../../../src/types/coreApi-types'
import { theme } from '../../../theme/theme'
import { Ionicons } from '@expo/vector-icons'
import PrimaryButton from '../../../components/PrimaryButton'
import { t } from 'i18n-js'

type Props = {
  question: QuestionDto
  answersState: string[]
  setAnswersState: Dispatch<SetStateAction<string[]>>
}

export default function MultipleChoiceImageQuestion({
  question,
  answersState,
  setAnswersState,
}: Props) {
  const { colors } = theme

  const none = answersState.includes('None')

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: 6,
        }}
      >
        {question.options?.map((option, index) => {
          const active = answersState.includes(option)
          return (
            <TouchableOpacity
              key={option}
              activeOpacity={1}
              style={{
                opacity: active ? 0.8 : 1,
                width: '49%',
                height: 125,
                borderRadius: 8,
                overflow: 'hidden',
              }}
              onPress={() => {
                if (active)
                  return setAnswersState(value => [
                    ...value.filter(item => item !== option),
                  ])
                if (question.maximum && answersState.length >= question.maximum)
                  return
                setAnswersState(value => [
                  ...value.filter(item => item !== 'None'),
                  option,
                ])
              }}
            >
              {active && (
                <View
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 15,
                    backgroundColor: colors.bg,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    zIndex: 10,
                    top: 5,
                    right: 5,
                  }}
                >
                  <Ionicons
                    name="checkmark-sharp"
                    size={18}
                    color={colors.accent}
                    style={{ zIndex: 10 }}
                  />
                </View>
              )}
              <Image
                style={styles.image}
                resizeMode="cover"
                source={{
                  uri: option,
                }}
              />
            </TouchableOpacity>
          )
        })}
      </View>
      <PrimaryButton
        secondary={!none}
        rounded
        renderLeft={
          <View
            style={{
              height: 22,
              width: 22,
              borderRadius: 11,
              borderColor: none ? colors['accent-text'] : colors.accent,
              borderStyle: 'solid',
              borderWidth: 1,
              backgroundColor: none ? colors.accent : colors['grey-button'],
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {none && (
              <View
                style={{
                  height: 16,
                  width: 16,
                  borderRadius: 8,
                  backgroundColor: colors['accent-text'],
                }}
              ></View>
            )}
          </View>
        }
        title={t('events.none-of-the-above')}
        style={{ alignSelf: 'center', marginTop: 25, height: 45 }}
        onPress={() => setAnswersState(['None'])}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'black',
  },
  image: {
    width: '100%',
    height: '100%',
  },
})
