import React from 'react'
import { View, StyleSheet } from 'react-native'
import type { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { ProfileNavigatorParamList } from '../../types/NavigationTypes'
import { RouteProp } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import PrimaryButton from '../../components/PrimaryButton'
import Page from '../../components/Page'
import { theme } from '../../theme/theme'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { Header } from '../../components/Text'
import ProfileButton from './components/ProfileButton'
import { useGetProfileQuery } from '../../redux/services/coreApi'
import { t } from 'i18n-js'

type ProfileScreenNavigationProps = NativeStackNavigationProp<
  ProfileNavigatorParamList,
  'ProfileScreen'
>
type ProfileScreenRouteProps = RouteProp<
  ProfileNavigatorParamList,
  'ProfileScreen'
>

type Props = {
  navigation: ProfileScreenNavigationProps
  route: ProfileScreenRouteProps
}

export default function ProfileScreen({ navigation, route }: Props) {
  const session = useSelector((state: RootState) => state.session)

  const { currentData: profile, isFetching: isGettingProfile } =
    useGetProfileQuery(null)

  const { colors } = theme

  const profileButtons = [
    {
      title: t('profile.account-and-profile'),
      onPress: () => {
        navigation.navigate('EditProfileScreen')
      },
    },
    {
      title: t('profile.help'),
      onPress: () => {
        navigation.navigate('SupportScreen')
      },
    },
    {
      title: t('profile.about'),
      onPress: () => {
        navigation.navigate('AboutScreen')
      },
    },
  ]

  const firstNameOrGuest =
    profile?.user?.fullName.split(' ')[0] ?? t('profile.guest')

  return (
    <Page backButtonDisabled loading={isGettingProfile}>
      <View style={styles.container}>
        <View>
          <View style={styles.circle}>
            <FontAwesomeIcon icon={faUser} color={colors.accent} size={18} />
          </View>
          <Header style={{ alignSelf: 'center', textTransform: 'capitalize' }}>
            {t('profile.hi')} {firstNameOrGuest}!
          </Header>
          {session.guestProfile && !session.token && (
            <PrimaryButton
              rounded
              style={{ alignSelf: 'center', marginTop: 20 }}
              renderRight={
                <FontAwesomeIcon
                  size={10}
                  style={{ color: colors['accent-text'] }}
                  icon={faChevronRight}
                />
              }
              title={t('profile.complete-setup')}
              onPress={() => {
                navigation.navigate('GuestCompleteSignupScreen')
              }}
            />
          )}
          <View style={{ marginTop: 40 }}>
            {profileButtons.map(item => (
              <ProfileButton
                key={item.title}
                title={item.title}
                onPress={item.onPress}
              />
            ))}
            {/* <PrimaryButton
              secondary
              title={t('danish')}
              onPress={() => {
                setLocale('da')
              }}
            />
            <PrimaryButton
              secondary
              title={t('english')}
              onPress={() => {
                setLocale('en')
              }}
            /> */}
          </View>
        </View>
      </View>
    </Page>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    flex: 1,
    marginHorizontal: -16,
  },
  circle: {
    width: 68,
    height: 68,
    borderRadius: 34,
    backgroundColor: theme.colors['grey-button'],
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 6,
  },
})
