export const suffixHelper = (rank: number | undefined) => {
  if (!rank) return null

  switch (rank) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    case 4:
      return 'th'
    case 5:
      return 'th'
    case 6:
      return 'th'
    case 7:
      return 'th'
    case 8:
      return 'th'
    default:
      return 'th'
  }
}
