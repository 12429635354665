import React, { useState, Dispatch, SetStateAction, useEffect } from 'react'
import { View, StyleSheet } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { theme } from '../../../theme/theme'
import { Description } from '../../../components/Text'
import { t } from 'i18n-js'

type PasswordValidationChecksProps = {
  password: string
  confirmPassword: string
}

export default function PasswordValidationChecks({
  password,
  confirmPassword,
}: PasswordValidationChecksProps) {
  const { colors } = theme
  const checks = [
    {
      title: t('validation.minimum-8-characters'),
      checked: password.length >= 8,
    },
    {
      title: t('validation.one-lowercase'),
      checked: RegExp(/^(?=.*[a-z])/g).test(password),
    },
    {
      title: t('validation.one-uppercase'),
      checked: RegExp(/^(?=.*[A-Z])/g).test(password),
    },
    {
      title: t('validation.one-number'),
      checked: RegExp(/^(?=.*\d)/g).test(password),
    },
    {
      title: t('validation.matching-password'),
      checked: password && password === confirmPassword,
    },
  ]

  return (
    <View style={{ gap: 10, marginTop: 14 }}>
      {checks.map(check => {
        const color = check.checked ? colors['bottle-green'] : colors.border
        return (
          <View
            key={check.title}
            style={{ flexDirection: 'row', gap: 8, alignItems: 'center' }}
          >
            <FontAwesomeIcon icon={faCheckCircle} color={color} size={15} />
            <Description
              style={{ color: color, fontFamily: theme.fonts.medium }}
            >
              {check.title}
            </Description>
          </View>
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({})
